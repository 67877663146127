
//menu bar
.secondary-menu {
    &.nav {
      background-color: @blue-dark;
      border: none;
      color: #ffffff;
    }
    &:after {
      background-color: transparent;
      height: 0px;
    }
}

ul.secondary-nav {
    position: relative;
    width: auto;
    display: block;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    li {
      display: block;
      float: left;
      padding: 0;
      margin: 0;
      font-size: 14px;
      font-weight: bold;
      a, span {
        text-decoration: none;
        height: 32px;
        line-height: 22px;
      }
      a {
        display: block;
        color: rgba(255,255,255,0.8);
        font-weight: 500;
        background: -moz-linear-gradient(left, rgba(255,255,255,.05) 0%, @blue-dark 100%);
        background: -webkit-linear-gradient(left, rgba(255,255,255,.05) 0%,@blue-dark 100%);
        background: linear-gradient(to right, rgba(255,255,255,.05) 0%, @blue-dark 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(255,255,255,.05)', endColorstr='@blue-dark',GradientType=1 );
        padding: 5px 20px;
        font-size: 16px;
        transition: all .3s;
        &:hover {
          color: #fff;
          background: @blue;
        }
      }
      &.active {
        a {
          color: #fff;
          background: @blue;
        }
      }
      &.inactive {
        span {
          color: #ccc;
          font-size: 0.9em;
        }
      }
    }
  }

// User steps
.user-steps-menu {
    padding: 0;
    margin: 0 0 0;
    background-color: @basegrey;
    
    .user-steps-menu-wrapper {
        border-bottom: none;
    }
    .current-service-container {
        background: #e1e4ea;
        box-shadow: -1000px 0 0 #e1e4ea, 1000px 0 0 #e1e4ea;
        
        .current-service-text {
            display: inline-flex;
            background-color: transparent;
            padding: 0 1.5rem 0 0;
            font-size: 18px;
            font-weight: 500;
            color: #404451;
            transition-duration: 0.2s;

            .home-button {
                color: #404451;
                padding: 0 9px;
                margin: 0;
                margin-inline-start: -9px;
                margin-inline-end: 6px;

                &:hover {
                    background: #3b8fb9;
                    color: white;
                }
            }
            .fa-chevron-right {
                font-size: 10px;
                margin-inline-end: 12px;
            }
        }
        .regs-docs-fees-cart {
            background-color: #b9c0ce;
            padding: 4px 2rem;
            cursor: pointer;
            color: white;
            text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
            font-size: 18px;
            line-height: 1.4;

            .count {
                background-color: #3b8fb9;
                color: white;
                border-radius: 24px;
                display: inline-flex;
                position: relative;
                top: -8px;
                font-size: 10px;
                font-weight: 500;
                line-height: 1;
                align-items: center;
                inset-inline-start: -5px;
                width: 14px;
                height: 14px;
                justify-content: center;
                box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
                text-shadow: none;
            }
            .fees {
                font-weight: 600;

                .currency {
                    font-weight: 400;
                    font-size: 10px;
                }
            }
            &:hover {
                background-color: #3b8fb9;
                color: white;
                border-color: #3b8fb9;

                .count {
                    background-color: #efefef;
                    box-shadow: 1px 2px 2px #666;
                    color: #157eb3;
                    font-weight: 600;
                }
            }
        }
        .service-cart {
            form {
                > .form-group {
                    margin: 0 !important;
                }
            }
        }
    }
    &.affix {
        -webkit-box-shadow: 1px 3px 10px 2px rgba(0,0,0,0.15);
        -moz-box-shadow: 1px 3px 10px 2px rgba(0,0,0,0.15);
        box-shadow: 1px 3px 10px 2px rgba(0,0,0,0.15);
        transition: all .3s;
        z-index: 23456;

        .user-steps-menu-wrapper {
            border-bottom: none !important;
        }       
        .current-service-container {            
            .current-service-text {
                font-size: 16px;
            }
        }
        .user-steps-menu-list {
            li {
                a {
                    font-size: 1.5rem;
                    padding: 1rem 2.5rem;
                }
                .chevron::before {
                    height: 1.6rem;
                    top: 0rem;
                    width: 1.6rem;
                }
            }
        }
    }

    .user-steps-menu-list {
        background-color: transparent;
        li {

            &:nth-child(1) {
                z-index: 5;
            }
            &:nth-child(2) {
                z-index: 4;
            }
            &:nth-child(3) {
                z-index: 3;
            }
            &:nth-child(4) {
                z-index: 2;
            }
            &:nth-child(5) {
                z-index: 1;
            }
            margin: 0;
            background: transparent;
            border-radius: 0;
            position: relative;
            flex-basis: 0;
            flex-grow: 2;
            padding: 0 0 0 3rem;
            height: auto;
            text-align: center;
            > a {
                text-align: center;
                font-size: 2rem;
                padding: 1.8rem 2.5rem;
                text-transform: uppercase;
                font-weight: 600;
                color: fade(@dark,25%);
                transition: all .3s;
            }

            &:after {
                content: '';
                display: none;
            }

            .user-steps-menu-item-progress {
                height: 2px;
                top: 100%;
                width: 0;
                position: absolute;
                border-radius: 5px 0 0 5px;
                border-inline-end: none;
                opacity: .9;
                background: @success-light;
                margin-inline-start: -3rem;
            }
            
            .chevron {
                position: absolute;
                inset-inline-end: -1.6rem;
                top: 1.5rem;
                color: fade(@dark, 32%);
                opacity: 0.5;
            }
            
            .chevron::before {
                border-style: solid;
                border-width: 0.12em 0.12em 0 0;
                content: '';
                display: inline-block;
                height: 2.3rem;
                position: relative;
                top: 0.5rem;
                transform: rotate(-45deg);
                vertical-align: top;
                width: 2.3rem;
                background: @basegrey;
            }
            
            .chevron.right:before {
                inset-inline-start: 0;
                transform: rotate(45deg);
            }

            &:last-child .chevron {
                display: none;
            }

            &:focus,
            &:active,
            &:hover,
            &.active {
                cursor: pointer;
                > a {
                    color: @dark;
                }
            }

            &.user-steps-menu-step-completed {
                .chevron {
                    color: @success-light;
                    opacity: 1;
                }
                .user-steps-menu-item-progress {
                    width: 100% !important;
                }
            }
        }
    } 
}

.finished-service-bar {
    background: #e1e4ea;
    box-shadow: -1000px 0 0 #e1e4ea, 1000px 0 0 #e1e4ea;
    margin: -24px 0 24px;
    
    .current-service-text {
        display: inline-flex;
        background-color: transparent;
        padding: 0 1.5rem 0 0;
        font-size: 18px;
        font-weight: 500;
        color: #404451;
        transition-duration: 0.2s;

        .home-button {
            color: #404451;
            padding: 0 9px;
            margin: 0;
            margin-inline-start: -9px;
            margin-inline-end: 6px;

            &:hover {
                background: #3b8fb9;
                color: white;
            }
        }
        .fa-chevron-right {
            font-size: 10px;
            margin-inline-end: 12px;
        }
    }
}

.dropdown-menu-scroller {
    max-height: 180px;
    overflow-y: scroll;
    background: #fafafa;
}

.dropdown-menu-hint {
    background: #fafafa;
}

@media (max-width: 768px) {
    .user-steps-menu {
        .user-steps-menu-list {
            padding: 0;
            .chevron {
                display: none;
            }
        }

        .navbar-collapse {
            .user-steps-menu-list {
                li {
                    &.active {
                        display: none;
                    }
                }
            }
        }
    }
}