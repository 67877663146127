html {
    background: #ffffff;
}

body {
    min-height: 100vh;
    height: auto;
    height: initial;
    position: relative;
    background: #f5f5f5;
    display: flex;
    flex-flow: column;
}

.progressColumn .progress {
    margin-bottom: 0;
    margin-top: 3px;
}

#menubar.mario {
    z-index: 1005;
}

#menubar .menubar-foot-panel.custom {
    background-color: #ffffff;
    padding: 16px 3px;
}

#menubar .menubar-foot-panel.custom img {
    padding-bottom: 10px;
}

.table .filters td {
    vertical-align: top;
}

.header-nav-profile > li {
    padding: 20px 0 7px 12px;
}

.header-nav-profile {
    margin-inline-end: 0;
    margin-inline-start: 0;
}



/*.form-group .form-control ~ label, .form-group > label, .form-group .control-label {
    font-size: 13px;
    opacity: 1;
}
.form-control {
    font-size: 13px;
}*/
.progress-bar {
    box-shadow: none;
}

/*#guide .form-group {
    margin-bottom: 5px;
}*/

#navigation .affix {
    top: 78px;
    max-width: 230px;
    transition: all .5s;
    -o-transition: all .5s;
    -webkit-transition: all .5s;
}

a.anchor {
    display: block;
    position: relative;
    top: -70px;
    visibility: hidden;
}

#toast-container > div {
    min-width: 400px;
}

/*.form-group .error ~ span.help-block
{
    color:#f44336;
}*/

blockquote footer::before, blockquote small::before, blockquote .small::before {
    content: "";
}

blockquote.no-margin {
    padding: 9px 19px;
}

blockquote small, blockquote .small {
    line-height: 1.6;
}

.dashboard .affix {
    z-index: 999;
    top: 10px;
    inset-inline-end: 25px;
    -webkit-transition: all .5s;
    transition: all .5s;
}

.dashboard .affix-top {
    top: 15px;
}

#mario {
    opacity: .5;
}

#mario:hover {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.22, 0.99, 0.68, 0.98) .5s;
}

.mario .list .tile .tile-content {
    padding-inline-start: 12px;
}

.mario .list {
    margin: 0 0 12px;
}

.mario .gui-controls > li {
    margin-bottom: 2px;
}

#quick-menu {
    padding: 28px 0 39px;
}

#quick-menu a {
    padding: 8px 16px;
    font-size: 16px;
}

#quick-menu .dropdown-menu > li > a {
    padding: 0 16px;
}

#quick-menu .list .tile .tile-text {
    padding: 10px 0;
}

#quick-menu .list .tile .tile-icon {
    min-width: 36px;
}


.no-margin-bottom {
    margin-bottom: 0;
}

.no-padding-bottom {
    padding-bottom: 0;
}

.btn-gray-dark {
    color: #fff;
    background-color: #333333;
    border-color: #333333;
}

.btn-register {
    font-size: 14px;
}

.btn {
    letter-spacing: 0.4pt;
}

.modal-dialog {
    margin: 64px auto;
}

/* Resizable layer */
.modal {
    &.component-product-list-modal {
        .modal-dialog {
            &.resizable-dialog {
                .resizer {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    inset-inline-start: -12px;
                    width: 12px;
                    background: transparent;
                    transition: all .3s;
                    display: block;
                    line-height: calc( 100vh - 9.1rem );
                    font-weight: 500;
                    font-size: 1.325rem;
                    color: transparent;
                    text-align: center;
                    z-index: 123457;

                    span {
                        display: block;
                        height: 100%;
                        background-image: linear-gradient(90deg, #efefef 20%, #333333 20%, #333333 40%, #efefef 40%, #efefef 60%, #333333 60%, #333333 80%, #efefef 80%, #efefef 100%);
                        background-size: 9px 25px;
                        background-repeat: no-repeat;
                        background-position: center center;
                        opacity: 0;
                    }
                }
      
                &.resize-active,
                &:active,
                &:focus,
                &:hover {
                    .resizer {
                        background: #efefef;
                        color: #b3b3b3;
                        cursor: col-resize;

                        span {
                            opacity: 1;
                            cursor: col-resize;
                        }
                    }
                }
            }
        }
    }
}

.modal-content {
    border: none;
}

#demarche h6 {
    margin: 6px 0 0;
}

#demarche .plus {
    text-transform: none;
    font-size: 9px;
    width: 54px;
}

#home-spacer {
    margin: 0 0 17px;
}

.opacity-90 {
    opacity: .9;
}

#assistant ul.nav-tabs > li a {
    padding-inline-start: 50px;
}

#assistant ul.nav-tabs > li a {
    padding-inline-start: 50px;
}

#assistant ul.nav-tabs > li a:hover, #assistant ul > li a:focus, #assistant ul > li.active a {
    background-color: #A5A5A5;
    color: white;
    opacity: 1;
    border-radius: 0;
    border: 0;
}

/*.help-block {
    bottom: -15px;
    font-size: 10px;
}*/
.header-nav-profile .dropdown > a, .header-nav-profile .dropdown.open > a {
    min-width: 180px;
    min-height: 64px;
    padding-block: 14px;
    padding-inline-start: 0;
    padding-inline-end: 8px;
}

.header-nav-profile .dropdown.open > a {
    margin-top: 0;
}

.header-nav-profile .dropdown .profile-info {
    padding: 0 13px 0 10px;
}

.header-nav-profile .dropdown > a:after {
    inset-inline-end: 9px;
    top: 24px;
    -webkit-transition: all .5s;
    transition: all .5s;
}

.header-nav-profile .dropdown.open > a:after {
    top: 24px;
}

.header-nav-profile .dropdown-menu > li > a {
    padding: 5px 10px;
}

.header-nav-profile .dropdown.open .dropdown-menu {
    margin-top: 0;
}

.margin-bottom {
    margin-bottom: 10px;
}

/*.section-body:first-child {
    margin-top: 12px;
}*/
form.navbar-search .form-control {
    background-color: transparent;
}

.no-top-padding {
    padding-top: 0;
}

.top-padding {
    padding-top: 24px;
}

.padding-top-xs {
    padding-top: 6px;
}

#base {
    min-height: unset;
    min-height: initial;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
}

#content {
    /*min-height: 100vh;*/
    padding-top: 0;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
}

.secondary-menu {
    position: relative;
    width: 100%;
    margin: 0 auto;
    /*height: 32px;*/
    border-radius: 0;
}

.secondary-menu:after {
    display: block;
    height: 2px;
    background-color: #333;
}

/* End of Main navigation */

.navbar-search input.input-sm {
    height: 30px;
}

.step-header .drop-cap {
    float: left;
    margin: 0;
    font-size: 67px;
    line-height: 0.9;
    width: 45px;
}

.step-header .step-subtitle {
    font-size: 17px;
    margin-inline-start: 45px;
    line-height: 1.2;
    font-weight: normal;
    text-transform: none;
}

.imageAttachment .no-image {
    color: #777;
    font-weight: normal;
    font-size: 15px;
    padding-top: 20px;
    vertical-align: middle;
    display: block;
    text-align: center;
}

.radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline {
    margin-inline-start: 0;
}

.radio-inline:not(:last-child), .checkboxproduction -inline:not(:last-child) {
    margin-inline-end: 10px;
    top: 5px;
}

.radio-inline, .checkbox-inline {
    top: 5px;
}

.form-field-type-htmlelement {
    margin-top: 5px;
}

.list .tile .checkbox-styled:not(ie8) input ~ span, .list .tile .radio-styled:not(ie8) input ~ span {
    padding-inline-start: 24px;
}

#send .list .tile .radio-styled:not(ie8) label {
    padding-inline-start: 0;
}

#guide .radio-form .form-group {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 0;
    margin-bottom: 0;
}

#guide .radio-form .form-group:nth-child(n-1):after {
    background: none;
}

#guide .checkbox-styled:not(ie8), #guide .radio-styled:not(ie8) {
    margin-top: 0;
    margin-bottom: 0;
}

#guide .radio label, #guide .checkbox label {
    margin-bottom: 0;
}

.vertical-center {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

table th {
    font-weight: bold;
}

.affixed-menu {
    top: 0;
    z-index: 6;
    width: 100%;
}

.affixed-menu-container {
    min-height: 58px;
}

.affix-menu-size-container {
    min-height: 76px;
}

.small-padding-top {
    padding: 12px 24px;
}

.small-padding {
    padding: 16px;
}

.input-group-addon {
    line-height: inherit;
}

/*textarea.form-control {
    padding: 4px 10px;
}*/
.bullet {
    width: 1.2em;
    height: 1.2em;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    font-size: 90%;
    line-height: 1.2;
    vertical-align: top;
}

.bullet-list {
    position: relative;
    top: 0;
    margin-inline-end: 10px;
}

.table thead > tr > th {
    color: #000000;
}

.no-padding-right {
    padding-inline-end: 0;
}

.list .tile div.tile-btn {
    display: block;
    padding-block: 16px;
    padding-inline-start: 0;
    padding-inline-end: 16px;
}

.list .tile .tile-text {
    padding: 16px 0;
}

.new span {
    color: white;
    text-decoration: none;
    padding: 3px 0 2px 5px;
    background: #b89367;
    position: relative;
    margin-inline-end: 15px;
    line-height: 26px;
}

.new span:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-inline-start: 10px solid #b89367;
    position: absolute;
    top: 50%;
    margin-top: -9px;
    inset-inline-start: 100%;
    z-index: 2;
}

span.required-star:before {
    content: "*";
    color: red;
    font: inherit;
}

.has-success span.required-star:before {
    content: "\f299";
    color: inherit;
    display: inline-block;
    font: normal normal normal 14px/1 'Material Design Iconic Font';
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.has-error span.required-star:before {
    content: "\f0a1";
    color: inherit;
    display: inline-block;
    font: normal normal normal 14px/1 'Material Design Iconic Font';
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

blockquote.withfooter {
    margin: 0;
    padding: 3px 0 3px 19px;
}

blockquote.small-padding {
    margin: 0;
    padding: 10px 18px;
}

#guide .form-group {
    margin-bottom: 12px;
}

.intl-tel-input {
    display: block;
    width: 100%;
}

a.btn-xs > i.md {
    vertical-align: middle;
}

.carousel-controls .carousel-control {
    background: #fff;
    font-size: 12px;
    width: 35px;
    height: 35px;
    top: 38px;
    margin-top: -27px;
    line-height: 35px;
    opacity: 1;
    text-shadow: none;
    z-index: 5;
    -webkit-transition: all 0.2s ease-in-out 0s;
    -o-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
}

.carousel-controls .carousel-control.left {
    inset-inline-start: inherit;
    inset-inline-end: 0;
    margin-inline-end: 48px;
}

.carousel-controls .carousel-control.right {
    margin-inline-end: 11px;
}

/*
.carousel-controls .carousel-control:hover {
    background: #fff;
    color: #000;
}*/
.null {
    font-size: .9em;
    font-style: italic;
    opacity: .6;
    color: #FF1744;
}

table.th-width th {
    width: 50%;
}

.scroll-height {
    height: 700px;
}

.iframe {
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    vertical-align: top;
    display: block;
    width: 100%;
    height: 100%;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    color: #979B98;
}

.full-bleed .section-body.with-margin-top:first-child {
    margin-top: 24px;
}

.card-head .tools form, .card-head .tools form .form-group, .card-head .tools form .checkbox.checkbox-styled {
    margin-bottom: 0;
}

.fileupload-progress .progress {
    margin-bottom: 8px;
}

.form-horizontal .control-label.text-left {
    text-align: start;
}

.btn-margin-top {
    margin-top: 25px;
}

.label.label-xs {
    display: inline;
    padding: .1em .3em .1em;
    font-size: 70%;
    font-weight: bold;
    vertical-align: middle;
}

.table .title {
    padding-inline-start: 24px;
}

.table .summary-line .title {
    padding-inline-start: 12px;
    font-weight: bold;
    text-transform: uppercase;
}

.header-nav-profile .dropdown-menu {
    padding: 0;
    border-radius: 1px;
}

#entreprise-grid tbody > tr.no-pointer:hover {
    cursor: inherit;
}

.form-inline.editableform .btn {
    margin-top: inherit;
    margin-inline-end: inherit;
}

.btn-success.disabled, .btn-success[disabled], .btn-success[disabled]:focus, .btn-success[disabled]:hover,
.btn-primary.disabled, .btn-primary[disabled], .btn-primary[disabled]:focus, .btn-primary[disabled]:hover,
.btn-accent.disabled, .btn-accent[disabled], .btn-accent[disabled]:focus, .btn-accent[disabled]:hover,
.btn-gray.disabled, .btn-gray[disabled], .btn-gray[disabled]:focus, .btn-gray[disabled]:hover {
    border-color: transparent;
    background: rgba(0, 0, 0, 0.15);
    color: #313532;
    opacity: 0.4;
    filter: alpha(opacity=40);
}

.side-padding {
    padding: 0 10px;
}

.list .tile .tile-text {
    font-size: 13px;
    padding: 10px 0;
}

.background-photo {
    background-size: cover;
    background-position: top right;
    top: -50px;
    max-width: 1200px;
    max-height: 800px;
}

h2.title {
    margin-top: 50px;
    margin-bottom: 20px;
    margin-inline: 0;
    line-height: 1.2;
}

h3.subtitle {
    margin-top: 0;
    margin-bottom: 10px;
    margin-inline: 0;
    text-align: justify;
    font-size: 125%;
}

.slogan {
    margin-top: 18px;
    margin-bottom: 2px;
    font-size: 225%;
    text-align: center;
}

.table-responsive-container {
    overflow-x: auto;
}

#applications-list {
    ul.warning-list {
        background: #f2dbd3 !important;
        box-shadow: -1000px 0 0 #f2dbd3, 1000px 0 0 #f2dbd3;
        margin-top: -48px;
        padding: 15px 0;
        margin-bottom: 28px;

        li {
            background: #f2dbd3 !important;
            margin-bottom: 9px !important;

            > span:not(.close) {
                border-inline-start: solid 2px #c2afa9;
                padding: 2px 13px;
                display: inline-block;
                white-space: pre-line;
            }

            &.alert.alert-warning {
                padding-inline-start: 0 !important;

                &::after {
                    display: none !important;
                }
            }

            &:last-of-type {
                margin-bottom: 0 !important;
            }
        }
    }
}

@media (max-width: 768px) {
    #secondary-menu {
        top: 54px;
    }

    h2.title {
        margin: 35px 0;
    }

    h3.subtitle {
        margin-top: 0;
        margin-bottom: 10px;
        margin-inline: 0;
        width: 45%;
    }
}

@media (max-width: 640px) {
    .background-photo {
        background-size: cover;
        background-position: top left;
        bottom: 0;
        width: 1200px;
    }

    .no-side-padding {
        padding: 10px 0;
    }

    h2.title {
        font-size: 22px;
        margin: 20px 0;
        line-height: 1.2;
    }

    h3.subtitle {
        margin-top: 0;
        margin-bottom: 10px;
        margin-inline: 0;
        width: 100%;
    }

    .slogan {
        margin-top: 18px;
        margin-bottom: 2px;
        font-size: 210%;
        text-align: center;
        line-height: 33px;
    }
}

@media (max-width: 480px) {
    .form-field-type-table {
        > div > table.table > tbody {
            > tr {
                display: block;

                > td {
                    display: block;
                }
            }
        }
    }
}

@media (max-width: 320px) {
}



#menu-info .element {
    display: inline-block;
}

#menu-info .element:first-child::before {
    content: normal;
}

#menu-info .element::before {
    content: "|";
    padding-inline-end: 7px;
}

#menu-info .element:not(:last-child)::after {
    content: "";
    padding-inline-end: 5px;
}

.radio-styled.radio-lg:not(ie8) input:checked ~ span:after {
    inset-inline-start: 5px;
    top: 5px;
    width: 10px;
    height: 10px;
}

.radio-styled.radio-lg:not(ie8) input ~ span:before {
    width: 20px;
    height: 20px;
}

.form-inline .checkbox, .form-inline .btn {
    margin-top: 4px;
}

li.step_disabled #progress_value_pay {
    fill: #666;
}

/************************/
/*   Angular pointer    */
/************************/
[ng-click],
[data-ng-click],
[x-ng-click] {
    cursor: pointer;
}

td.text-in-middle {
    vertical-align: middle !important;
}

.sortorder:after {
    content: '\25b2';
}
.sortorder.reverse:after {
    content: '\25bc';
}

.tabs-accent.nav-tabs > li.active > a,
.tabs-accent.nav-tabs > li.active > a:hover,
.tabs-accent.nav-tabs > li.active > a:focus {
    height: 47px;
}

/* Styling for the ngProgress itself */
#ngProgress {
    z-index: 999999998;
}

/* Styling for the ngProgress-container */
#ngProgress-container {
    z-index: 999999999;
}

.cms-debug-bar { display: none; }

/* formio fix */
.form-group .form-field-type-select {
    display: block !important;
}

table.datagrid-table .field-required-inline {
    display: none;
}

.glyphicon-asterisk {
    font-family: "Open Sans",sans-serif,Helvetica,Arial,sans-serif;
    font-weight: 500;
}

.input-group .form-control:only-child{
    border-radius: 6px;
}
#form-type-A1 .input-group .form-control:first-child {
    border-radius: 6px;
}

.input-group-btn {
    width: 1%;
}
.form-field-type-datetime .input-group-btn {
    width: 1%;
}
.ui-select-match > .btn-default {
    border-color: #ccc;
}

.dropdown-menu {
    border-color: #ccc;
}
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    border-color: #ccc;
    background: #e5e6e5;
    color: #313532;
}

#form-type-A1 .glyphicon-asterisk:before {
    content: "";
}
.table-grouped {
    background-color: rgba(242,243,242,0.35);
}


/* Styling table - no line on top of the row */
.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
/*    border-top: none;*/
    vertical-align: top;
}

.content-middle>tbody>tr>th, .content-middle>tbody>tr>td {
    vertical-align: middle;
}

.content-middle-padding>tbody>tr>th, .content-middle-padding>tbody>tr>td {
    vertical-align: middle;
    padding: 10px 8px;
}

.no-margin-table .table {
    margin: 0;
}

.form-field-type-datagrid .datagrid-table {
    margin: 0 -12px
}

.my-applications .table>tbody>tr>td {
    padding: 10px 8px;
}

.with-margin-top {
    margin-top: 24px;
}

.btn-menu, .btn-menu:active, .btn-menu:hover, .btn-menu:focus, .btn-menu:visited {
    color: #fff;
    background-color: #164271;
    border-color: #164271;
}

.padding {
    padding-inline-end: 14px;
}

.col-with-12 {
    width: 12%;
}
.form-field-type-table .form-group {
    margin-bottom: 0.5rem;
}

.no-margin-form-group .table{
    margin: 0;
}
.no-margin-form-group .table .form-group{
    margin: 0;
}
.no-margin-form-group .table>tbody>tr>td {
    vertical-align: middle;
}

.panel-body .panel-body {
    padding: 0;
}

.switch-button-label {
    float: left;
    font-size: 16pt;
    cursor: pointer;
    line-height: 1.4
}

.switch-button-label.off {
    color: #adadad
}

.switch-button-label.on {
    font-weight: bold
}

.switch-button-label.on span.yes {
    color: #4caf50
}

.switch-button-label.on span.no {
    color: #f44336
}

.switch-button-background {
    float: left;
    position: relative;
    background: #ccc;
    border: 1px solid #aaa;
    margin: 0 12px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    cursor: pointer
}

.switch-button-button {
    position: absolute;
    top: -1px;
    background: #fafafa;
    border: 1px solid #aaa;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    inset-inline-start: -1px;
}

.switch-button-background.checked .switch-button-button {
    inset-inline-start: 16px;
}

.formio-component-validationtable .switch-box .switch-button-label[ng-click="setSwitch(false)"],
.formio-component-rejectionrow .switch-box .switch-button-label[ng-click="setSwitch(false)"] {
    background: #efefef !important;
    color: #333333;

    &:hover,
    &.on {
        color: white !important;
        background-color: #d16e72 !important;
    }
}
.formio-component-rejectionrow .form-group.form-field-type-myswitch .switch-box .switch-button-label[ng-click="setSwitch(true)"]:hover {
    color: white !important;
    background-color: #80c49b !important;
}

.red-nit {
    color: red;
}
.white-space-normal {
    white-space: normal;
}
.confirm-button>button{
    color: #fff;
    background-color: #535854;
    border-color: #535854;
}
.print-button>button{
    color: #fff;
    background-color: #969c97;
    border-color: #969c97;
}
.sworn-statement .field-required:after {
    content: "";
}

.has-success .form-control {
    border-color: #4caf50;
}
.has-error .field-required:after {
    content: "\f0a1";
    color: inherit;
    display: inline-block;
    font: normal normal normal 14px/1 'Material Design Iconic Font';
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.has-success .field-required:after {
    content: "\f299";
    color: inherit;
    display: inline-block;
    font: normal normal normal 14px/1 'Material Design Iconic Font';
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.sworn-statement .has-error .field-required:after {
    content: "";
}
.sworn-statement .has-success .field-required:after {
    content: "";
}
.has-success .ui-select-match > .btn-default {
    border: 1px solid #4caf50;
}
.validation-headers .col-sm-1 {
    width: 1%;
    padding: 0 0 0 12px;
}
.validation-headers .col-sm-3 {
    width: 10%;
    text-align: end;
    padding: 0 12px 0 0;
    float: right;
}
.validation-headers .col-sm-8 {
    padding: 0 0 0 12px;
    width: 80%;
}
.validation-headers .form-group {
    margin-bottom: 0;
}
.validation-headers .btn {
    letter-spacing: .4pt;
    color: inherit;
    font-size: unset;
    padding: 0 6px;
  border: 0px solid transparent;
  white-space: normal;
  text-align: start;
}
.section-body:first-child {
    margin-top: 24px;
}
/* payment table */
#payment-tab .card-bordered, #payment-tab .card-outlined {
    border: none;
    margin-bottom: 0px;
}
#payment-tab .table>tbody>tr>td {
    vertical-align: baseline;
    padding: 10px 8px;
    line-height: 1.846153846;
    border-top: 1px solid rgba(189,193,190,0.2);
}
#payment-tab .table>tbody>tr>td p {
    margin: 0;
}
#payment-tab .table>tbody:first-child>tr:first-child>td {
    vertical-align: bottom;
    padding: 10px 8px;
    line-height: 1.846153846;
    border-top: 0;
    border-bottom: 2px solid rgba(189,193,190,0.2);
}
#payment-tab .form-field-type-table .form-group {
    margin-bottom: 0px;
}
#payment-tab {
    form {
        .formio-component-totalPrice {
            > .row {
                > .col-sm-6:last-of-type {
                    .form-field-type-mycontent {
                        span {
                            display: inline-block;
                            margin-top: 6px;
                        }
                    }
                }
            }
        }
        .default-pay-form-payments {
            border-top: none;
            border-bottom: 1px solid #efefef;
            padding-top: 10px;

            &:last-of-type {
                border-bottom: none;
            }
            &:first-of-type {
                padding-top: 0;
            }

            .row {
                div[class^="col-"] {
                    width: auto;

                    label {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

.paginator-wrapper {
    margin-inline-start: 20px;
}

.nowrap {
    white-space: nowrap;
}

input.form-control:disabled {
    text-align: start;
}




@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-o-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-ms-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes zoom-down {
    from {
        -webkit-transform-origin: top;
        -moz-transform-origin: top;
        -ms-transform-origin: top;
        transform-origin: top;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}
@-moz-keyframes zoom-down {
    from {
        -webkit-transform-origin: top;
        -moz-transform-origin: top;
        -ms-transform-origin: top;
        transform-origin: top;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}
@-o-keyframes zoom-down {
    from {
        -webkit-transform-origin: top;
        -moz-transform-origin: top;
        -ms-transform-origin: top;
        transform-origin: top;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}
@-ms-keyframes zoom-down {
    from {
        -webkit-transform-origin: top;
        -moz-transform-origin: top;
        -ms-transform-origin: top;
        transform-origin: top;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}
@keyframes zoom-down {
    from {
        -webkit-transform-origin: top;
        -moz-transform-origin: top;
        -ms-transform-origin: top;
        transform-origin: top;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}
@-webkit-keyframes zoom-up {
    from {
        -webkit-transform-origin: bottom;
        -moz-transform-origin: bottom;
        -ms-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}
@-moz-keyframes zoom-up {
    from {
        -webkit-transform-origin: bottom;
        -moz-transform-origin: bottom;
        -ms-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}
@-o-keyframes zoom-up {
    from {
        -webkit-transform-origin: bottom;
        -moz-transform-origin: bottom;
        -ms-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}
@-ms-keyframes zoom-up {
    from {
        -webkit-transform-origin: bottom;
        -moz-transform-origin: bottom;
        -ms-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}
@keyframes zoom-up {
    from {
        -webkit-transform-origin: bottom;
        -moz-transform-origin: bottom;
        -ms-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}
@-webkit-keyframes slide-right {
    from {
        -webkit-transform: translate(-20px, 0);
        -ms-transform: translate(-20px, 0);
        -o-transform: translate(-20px, 0);
        transform: translate(-20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@-moz-keyframes slide-right {
    from {
        -webkit-transform: translate(-20px, 0);
        -ms-transform: translate(-20px, 0);
        -o-transform: translate(-20px, 0);
        transform: translate(-20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@-o-keyframes slide-right {
    from {
        -webkit-transform: translate(-20px, 0);
        -ms-transform: translate(-20px, 0);
        -o-transform: translate(-20px, 0);
        transform: translate(-20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@-ms-keyframes slide-right {
    from {
        -webkit-transform: translate(-20px, 0);
        -ms-transform: translate(-20px, 0);
        -o-transform: translate(-20px, 0);
        transform: translate(-20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@keyframes slide-right {
    from {
        -webkit-transform: translate(-20px, 0);
        -ms-transform: translate(-20px, 0);
        -o-transform: translate(-20px, 0);
        transform: translate(-20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@-webkit-keyframes slide-left {
    from {
        -webkit-transform: translate(20px, 0);
        -ms-transform: translate(20px, 0);
        -o-transform: translate(20px, 0);
        transform: translate(20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@-moz-keyframes slide-left {
    from {
        -webkit-transform: translate(20px, 0);
        -ms-transform: translate(20px, 0);
        -o-transform: translate(20px, 0);
        transform: translate(20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@-o-keyframes slide-left {
    from {
        -webkit-transform: translate(20px, 0);
        -ms-transform: translate(20px, 0);
        -o-transform: translate(20px, 0);
        transform: translate(20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@-ms-keyframes slide-left {
    from {
        -webkit-transform: translate(20px, 0);
        -ms-transform: translate(20px, 0);
        -o-transform: translate(20px, 0);
        transform: translate(20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@keyframes slide-left {
    from {
        -webkit-transform: translate(20px, 0);
        -ms-transform: translate(20px, 0);
        -o-transform: translate(20px, 0);
        transform: translate(20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@-webkit-keyframes slide-down {
    from {
        -webkit-transform: translate(0, -20px);
        -ms-transform: translate(0, -20px);
        -o-transform: translate(0, -20px);
        transform: translate(0, -20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@-moz-keyframes slide-down {
    from {
        -webkit-transform: translate(0, -20px);
        -ms-transform: translate(0, -20px);
        -o-transform: translate(0, -20px);
        transform: translate(0, -20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@-o-keyframes slide-down {
    from {
        -webkit-transform: translate(0, -20px);
        -ms-transform: translate(0, -20px);
        -o-transform: translate(0, -20px);
        transform: translate(0, -20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@-ms-keyframes slide-down {
    from {
        -webkit-transform: translate(0, -20px);
        -ms-transform: translate(0, -20px);
        -o-transform: translate(0, -20px);
        transform: translate(0, -20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@keyframes slide-down {
    from {
        -webkit-transform: translate(0, -20px);
        -ms-transform: translate(0, -20px);
        -o-transform: translate(0, -20px);
        transform: translate(0, -20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@-webkit-keyframes slide-up {
    from {
        -webkit-transform: translate(0, 20px);
        -ms-transform: translate(0, 20px);
        -o-transform: translate(0, 20px);
        transform: translate(0, 20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@-moz-keyframes slide-up {
    from {
        -webkit-transform: translate(0, 20px);
        -ms-transform: translate(0, 20px);
        -o-transform: translate(0, 20px);
        transform: translate(0, 20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@-o-keyframes slide-up {
    from {
        -webkit-transform: translate(0, 20px);
        -ms-transform: translate(0, 20px);
        -o-transform: translate(0, 20px);
        transform: translate(0, 20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@-ms-keyframes slide-up {
    from {
        -webkit-transform: translate(0, 20px);
        -ms-transform: translate(0, 20px);
        -o-transform: translate(0, 20px);
        transform: translate(0, 20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@keyframes slide-up {
    from {
        -webkit-transform: translate(0, 20px);
        -ms-transform: translate(0, 20px);
        -o-transform: translate(0, 20px);
        transform: translate(0, 20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}


.labelmargin {
    margin-top: 24px;
}

.required {
    color: red;
}

.form-field-type-radio .form-control-feedback.field-required-inline {
    display: inline;
    position: relative;
}

.form-field-type-htmlelement {
    margin-top: 16px;
}

.user-steps-menu-wrapper {
    padding: 0;
}

.form-field-type-mypanel .panel-default>div.panel-heading,
.form-field-type-panel .panel-default>div.panel-heading {
    border: none;
}


.btn-lg, .btn-group-lg > .btn {
    padding: 5px 16px;
}

.panel {
    border-color: #E3E3E3;
    border-radius: 5px;
}

.form-field-type-panel {
    padding-bottom: 0;
}

.form-field-type-panel .panel {
    margin-bottom: 0;
}

.form-group.form-field-type-columns .form-group.form-field-type-columns {
    margin: 10px 16px 0;
}

.form-group > label,
.form-group .control-label {
    color: #2E2E2E;
}

.page-title {
    margin-bottom: 10px;
}

.service-list-item img {
    max-width: 64px;
    max-height: 64px;
}

.list .tile .tile-content {
    display: flex;
    padding-inline-start: 16px;
}

.list .tile div.tile-btn {
    display: flex;
}

.list .tile .tile-text {
    font-size: 16px;
    padding: 1.75rem 0;
}

.list .tile .tile-text h3 {
    margin-top: 0px;
}

.list .tile .tile-text a {
    font-size: 14px;
}

 .capital-first > div:first-child {
    font-weight: 500;
    color: #282666;
 }

 section .style-default-dark,
 .offcanvas-pane .style-default-dark,
 .card .style-default-dark,
 section.style-default-dark,
 .offcanvas-pane.style-default-dark,
 .card.style-default-dark {
    background-color: transparent;
    border: none;
    color: #ffffff;
}

.checkbox-styled:not(ie8) input ~ span:before, .radio-styled:not(ie8) input ~ span:before {
    top: 5px;
}

/* License Search */
.license-search-input-group {
    input.form-control {
        border-radius: 3px 0 0 3px !important;
    }
    .button-license-search {
        background-color: #3C8FB9;
        border-color: #3C8FB9;
        border-radius: 0 3px 3px 0;

        &:hover {
            background-color: #3680a6;
            border-color: #3680a6;
        }
    }
}
.license-search-messages {
    &.alert {
        background: rgba(239, 239, 239, 0.5) !important;
        color: #404451 !important;
        padding: 12px 20px !important;
        margin-top: 20px !important;
    }
}
