body {
  &.modern-theme {    
    #base {
      #content {
        .affix-menu-size-container {
          &.demo-modern {
            height: 186px;
          }
          .user-steps-menu {
            .user-steps-menu-wrapper {
              nav {
                #user-steps {
                  ul {
                    &.user-steps-menu-list {
                      margin-top: var(--wizard-page-menu-top-margin, 3rem);
                      height: var(--wizard-page-menu-height, 50px);
                      background-color: @whiteColor;
                      border-radius: @roundedCorners;
                      border: solid 1px @borderColor;

                      li {
                        padding: 0;
                        display: flex;
                        justify-content: center;

                        &:first-child {
                          border-radius: @roundedCorners 0 0 @roundedCorners;
                        }
                        &:last-child {
                          border-radius: 0 @roundedCorners @roundedCorners 0;
                        }
                        &:where(.active, :hover) {
                          background-color: @accentColor10;

                          a {
                            color: @accentColor;

                            .app-step-page-number {
                              font-weight: 900;
                            }
                          }
                        }
                        .chevron.right {
                          display: none;
                        }
                        a {
                          font-weight: 600;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          padding: 1.2rem 2.5rem;
                          color: @accentColor40;

                          .app-step-page-number {
                            font-size: 14px;
                            display: inline-flex;
                            border: solid 2px @accentColor40;
                            border-radius: 1in;
                            width: 22px;
                            min-width: 22px;
                            height: 22px;
                            line-height: 21px;
                            justify-content: center;
                            align-items: center;
                            margin-inline-end: 10px;
                            font-weight: 900;
                            position: relative;

                            .progress-full-check {
                              font-size: 2rem;
                            }
                            svg {
                              position: absolute;

                              &[hidden] {
                                display: block;
                              }
                              circle {
                                &.progress-ring__circle {
                                  stroke: @accentColor;
                                  transition: 0.35s stroke-dashoffset;
                                  transform: rotate(-90deg);
                                  transform-origin: 50% 50%;
                                  stroke-dasharray: 62, 62;
                                  /*stroke-dashoffset: 62; 62 = 0% ... 0 = 100% */

                                  &:where([style="stroke-dashoffset: 0"], [style="stroke-dashoffset: 62"], [style="stroke-dashoffset: 0;"], [style="stroke-dashoffset: 62;"]) {
                                    display: none;
                                  }
                                }
                              }
                            }
                            > span {
                              i {
                                display: none;
                              }
                            }
                          }
                          .app-step-page-name {
                            position: relative;
                            top: 1px;

                            > span {
                              text-transform: initial;
                              font-weight: 600;
                              font-size: 20px;
                            }
                          }
                        }
                        > .user-steps-menu-item-progress {
                          display: none;
                        }
                        &.user-steps-menu-step-completed {
                          a {
                            .app-step-page-number {
                              border: none;

                              > span {
                                display: none;
                              }
                              .progress-full-check {
                                &.hidden {
                                  display: block !important;
                                  visibility: visible !important;
                                  color: @successColor;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .demo-mode-heading {
                    background-color: @accentColor10;
                    box-shadow: -1200px 0 0 @accentColor10, -2400px 0 0 @accentColor10, 1200px 0 0 @accentColor10, 2400px 0 0 @accentColor10;
                    padding: 20px 0;
                    
                    h4 {
                      display: none;
                    }
                    span {
                      color: @accentColor;
                      font-weight: 600;
                    }
                  }
                }
              }
              #shoppingcart.service-cart {
                display: none;

                & > .regs-docs-fees-drawer {
                  background-color: @whiteColor;
                  border-radius: 0 0 @roundedCorners @roundedCorners;
                  height: auto;
                  position: absolute;
                  inset-inline-end: 0;
                  top: 100%;
                  z-index: 999;
                  width: calc(28% - 22px);
                  box-shadow: 0px 11px 14px @black30Color;
                  min-width: 300px;

                  .drawer-header {
                    display: none;
                  }
                  .drawer-body {
                    max-height: 74vh;
                    overflow-y: auto;

                    .drawer-section-header {
                      color: @darkTextColor;
                      border-top: solid 1px @accentColor10;
                      font-size: 15px;
                      padding-inline-end: 16px;

                      /*.section-regs .section-fees .section-docs*/
                      i {
                        border: none;
                        width: 18px;
                        height: 18px;
                        font-weight: 600;
                        font-size: 14px;
                        color: @darkTextColor80;

                        &::before {
                          line-height: 16px;
                        }
                      }
                      &.opened {
                        background-color: @accentColor;
                        color: @whiteColor;
                        
                        i {
                          color: @whiteColor;
                        }
                        &:where(.section-regs, .section-docs) {
                          & > span {
                            & > span {
                              &:last-child {
                                color: @accentColor;
                                background: @whiteColor;
                              }
                            }
                          }
                        }
                        &.section-fees {
                          span {
                            span {
                              &:last-child {
                                color: @whiteColor;
                              }
                            }
                          }
                        }
                      }
                      &:where(.section-regs, .section-docs) {
                        & > span {
                          & > span {
                            &:last-child {
                              font-size: 12px;
                              min-width: 16px;
                              height: 16px;
                              font-weight: 600;
                              background: @accentColor;
                              border-radius: 8px;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              color: @whiteColor;
                            }
                          }
                        }
                      }
                      &.section-docs {
                        &:not(.opened) {
                          border-radius: 0 0 @roundedCorners @roundedCorners;
                        }
                      }
                      &:last-of-type {
                        &:not(.opened) {
                          border-radius: @roundedCorners;
                        }
                      }
                      &:first-of-type {
                        &:not(.opened) {
                          border-top: none;
                        }
                      }
                      & > span {
                        flex: auto;
                        justify-content: space-between;
                        text-align: start;
                        display: flex;
                        align-items: center;

                       & > span {
                         &:last-child {
                           margin-inline-end: 1.6rem;
                           color: @accentColor;
                           font-weight: 900;
                         }
                        }
                      }
                    }
                    .drawer-section-content {
                      padding: 0;

                      &#guidemenurequirements {
                        border-radius: 0 0 @roundedCorners @roundedCorners;
                      }
                      &#guidemenuregs {
                        label {
                          > div {
                            position: relative;
                            font-weight: 600;
                            color: @accentColor50;
                            display: flex;
                            align-items: center;

                            input[type="checkbox"] {
                              appearance: none;
                              position: initial;
                              margin: 0;
                              width: auto;
                              height: auto;

                              &::after {
                                font-weight: 400;
                                content: "\F111";
                                position: absolute;
                                font-family: "Font Awesome 5 Pro";
                                -moz-osx-font-smoothing: grayscale;
                                -webkit-font-smoothing: antialiased;
                                display: inline-block;
                                font-style: normal;
                                font-variant: normal;
                                text-rendering: auto;
                                line-height: 1;
                                font-size: 16px;
                                top: -1px;
                                color: @accentColor50;
                                inset-inline-end: 0;
                                cursor: pointer;
                              }
                              &:checked {  
                                &::after {
                                  color: @accentColor;
                                  content: "\F058";
                                  font-weight: 900;
                                  top: calc(50% - 8px);
                                }
                                & + * {
                                  color: @accentColor;
                                }
                              }
                              & + span {
                                padding-inline-end: 24px;
                                line-height: 1.3;
                              }
                            }
                          }
                        }
                        > div {
                          > div {
                            padding-inline-end: 16px;

                            &:hover {
                              label {
                                > div {
                                  color: @accentColor;
        
                                  input[type="checkbox"] {    
                                    &::after {
                                      color: @accentColor;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      > div {
                        > div {
                          color: @darkTextColor;
                          border-top: solid 1px @accentColor10;
                          font-size: 14px;
                          font-weight: 600;
                          padding: 15px 20px;
                          line-height: 18px;
                          background-color: @accentColor3;

                          &:empty {
                            display: none;
                          }
                        }
                        &:first-child {
                          > div {
                            border-top: none;
                          }
                        }
                      }
                    }
                  }
                  form {
                    & > .form-field-type-mycontent {
                      & + .form-group {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
            @media screen and (max-width: 1240px) {
              padding-inline-start: 20px;
              padding-inline-end: 20px;
            }
          }
          &.affix {
            background-color: @whiteColor;
            top: 0;
            width: 100%;
            z-index: 1050;

            & ~ .section-body {
              padding-top: 40px;
            }
            .user-steps-menu {
              .user-steps-menu-wrapper {
                #shoppingcart.service-cart > .regs-docs-fees-drawer {
                  top: 84px;
  
                  .drawer-body {
                    max-height: 80vh;
                  }
                }
                nav {
                  #user-steps {
                    ul {
                      &.user-steps-menu-list {
                        margin-top: 0;
                        box-shadow: none;
                        height: 44px;
                        border: none;
  
                        li {
                          border-radius: 0;
  
                          a {
                            padding: 1rem 2.5rem;
  
                            .app-step-page-number {
                              font-size: 12px;
                              width: 18px;
                              height: 18px;
                              line-height: 16px;
                            }
                          }
                        }
                      }
                    }
                    &.has-fees-regs-docs-cart {
                      .service-cart {
                        &.modern-theme-cart {
                          height: 44px;
                          margin-top: 0px;
                          border-radius: 0px;
                          box-shadow: none;
                          border: none;
                          background-color: transparent;
  
                          form {
                            box-shadow: inset 50px 0;
  
                            &.formio-form {  
                              > .form-field-type-mycontent {
                                .regs-docs-fees-cart {  
                                  :where(.regs, .docs) {  
                                    span {
                                      &.count {
                                        width: 16px;
                                        height: 16px;
                                        top: -3px;
                                        font-size: 1.2rem;
                                      }
                                    }
                                  }
                                  i {
                                    font-size: 2.4rem;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    .demo-mode-heading {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.formio-new {
      #base {
        #content {
          .section-body {
            .contain-lg {
              #formio-form-wizard {
                .formio-form {
                  nav {
                    display: flex;
                    position: relative;

                    ul {
                      &.pagination {
                        margin-top: var(--wizard-page-menu-top-margin, 3rem);
                        margin-bottom: var(--wizard-page-menu-bottom-margin, 3rem);
                        height: var(--wizard-page-menu-height, 50px);
                        background-color: @whiteColor;
                        border-radius: @roundedCorners;
                        border: solid 1px @borderColor;
                        box-shadow: none;
                        flex: auto;

                        li {
                          &.page-item {
                            padding: 0;
                            display: flex;
                            justify-content: center;
                            background-color: transparent;

                            &:first-child {
                              border-radius: @roundedCorners 0 0 @roundedCorners;
                            }
                            &:last-child {
                              border-radius: 0 @roundedCorners @roundedCorners 0;
                            }
                            &.active {
                              background-color: @accentColor10;
                              
                              .page-link {
                                color: @accentColor;
    
                                .app-step-page-number {
                                  font-weight: 900;
                                }
                              }
                            }
                            .page-link {
                              font-weight: 600;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              padding: 1.2rem 2.5rem;
                              color: @accentColor40;
                              font-size: 20px;
                              text-transform: initial;
                              background: transparent;
                              line-height: 1;

                              &::after {
                                display: none;
                              }
                              &::before {
                                font-size: 14px;
                                display: inline-flex;
                                border: solid 2px @accentColor40;
                                border-radius: 1in;
                                width: 22px;
                                min-width: 22px;
                                height: 22px;
                                line-height: 21px;
                                justify-content: center;
                                align-items: center;
                                margin-inline-end: 10px;
                                font-weight: 900;
                                position: relative;
                              }
                              svg {
                                margin-inline-start: -33px;
                                margin-inline-end: 10px;
  
                                &[hidden] {
                                  display: block;
                                }
                                circle {
                                  &.progress-ring__circle {
                                    stroke: @accentColor;
                                    transition: 0.35s stroke-dashoffset;
                                    transform: rotate(-90deg);
                                    transform-origin: 50% 50%;
                                    stroke-dasharray: 62, 62;
                                    /*stroke-dashoffset: 62; 62 = 0% ... 0 = 100% */
  
                                    &:where([style="stroke-dashoffset: 0"], [style="stroke-dashoffset: 62"], [style="stroke-dashoffset: 0;"], [style="stroke-dashoffset: 62;"]) {
                                      display: none;
                                    }
                                  }
                                }
                              }
                              &.progress-complete {
                                &::before {
                                  color: @successColor;
                                  border-color: @successColor;
                                }
                                svg {
                                  display: none !important; // Patch for Safari
                                }
                              }
                              @media screen and (max-width: 980px) {
                                font-size: 16px;
                              }
                              @media screen and (max-width: 680px) {
                                font-size: 0;

                                &::before {
                                  margin-inline-end: 0;
                                }
                                svg {
                                  margin-inline-start: -23px;
                                }
                              }
                            }
                            .progress-bar {
                              display: none;
                            }
                          }
                        }
                      }
                    }
                    &.has-cart {
                      ul {
                        &.pagination {
                          width: 72%;
                        }
                      }
                      .cart-container {
                        width: auto;
                        min-width: 300px;
                        display: flex;
                        margin-inline-start: 22px;
                        position: relative;
                        justify-content: flex-end;
                        user-select: none;

                        .cart-title {
                          display: none;
                          color: @accentColor;
                          font-weight: 600;
                          font-size: 17px;
                          flex: auto;
                          align-items: center;
                          white-space: nowrap;

                          &::after {
                            font-family: "Font Awesome 5 Pro";
                            font-weight: 600;
                            -moz-osx-font-smoothing: grayscale;
                            -webkit-font-smoothing: antialiased;
                            display: inline-flex;
                            font-style: normal;
                            font-variant: normal;
                            text-rendering: auto;
                            line-height: 1;
                            content: "\f106";
                            width: 50px;
                            height: 100%;
                            align-items: center;
                            justify-content: center;
                            color: @accentColor;
                            margin-inline-start: auto;
                            position: absolute;
                            inset-inline-end: 0px;
                          }
                        }
                        .regs-text {
                          color: @accentColor;
                          font-weight: 500;
                          font-size: 15px;
                          margin-inline-end: 6px;
                          white-space: nowrap;
                        }
                        .regs-docs-fees-cart {
                          margin-inline-start: 0px;

                          &.open {
                            .cart-title {
                              display: flex;
                            }
                          }
                        }
                        .regs-docs-fees-drawer {
                          width: 100%;

                          &.open {
                            display: flex;
                            flex-direction: column;
                          }
                          .drawer-section-header {
                            font-weight: 500;

                            span span {
                              &:first-child {
                                margin-inline-end: auto;
                              }
                              &:last-of-type {
                                margin-inline-end: 16px;
                              }
                            }
                            &:where(.section-regs, .section-docs) {
                              span span {
                                &:last-of-type {
                                  font-size: 12px;
                                  min-width: 16px;
                                  height: 16px;
                                  font-weight: 600;
                                  background: @accentColor;
                                  border-radius: 8px;
                                  display: flex;
                                  align-items: center;
                                  justify-content: center;
                                  color: @whiteColor;
                                }
                              }
                              &.opened {
                                span span:last-of-type {
                                  color: @accentColor;
                                  background: @whiteColor;
                                }
                              }
                            }
                            i.ml-auto {
                              margin-inline-start: 0 !important;
                              display: grid;
                              place-content: center;
                            }
                          }
                          .drawer-section-content {
                            label {
                              cursor: pointer;
                              position: relative;
                              font-weight: 600;
                              color: @accentColor50;
                              display: flex;
                              align-items: center;
                              flex: auto;

                              input[type="checkbox"] {
                                appearance: none;
                                position: initial;
                                margin: 0;
                                width: auto;
                                height: auto;
  
                                &::after {
                                  font-weight: 400;
                                  content: "\F111";
                                  position: absolute;
                                  font-family: "Font Awesome 5 Pro";
                                  -moz-osx-font-smoothing: grayscale;
                                  -webkit-font-smoothing: antialiased;
                                  display: inline-block;
                                  font-style: normal;
                                  font-variant: normal;
                                  text-rendering: auto;
                                  line-height: 1;
                                  font-size: 16px;
                                  top: calc(50% - 8px);
                                  color: @accentColor50;
                                  inset-inline-end: 0;
                                  cursor: pointer;
                                }
                                &:checked {  
                                  &::after {
                                    color: @accentColor;
                                    content: "\F058";
                                    font-weight: 900;
                                  }
                                  & + * {
                                    color: @accentColor;
                                  }
                                }
                                & + span {
                                  padding-inline-end: 24px;
                                  line-height: 1.3;
                                }
                              }
                            }
                            &.opened {
                              display: block;
                            }
                            > div {
                              display: flex;
                              align-items: center;
                            }
                          }
                          .currency {
                            margin-inline-end: 4px;
                            
                            &:last-of-type {
                              margin-inline-start: 4px;
                              margin-inline-end: 0;
                            }
                          }
                          .reg-name {
                            margin-inline-end: auto;
                          }
                        }
                      }
                      // Old Cart structure compatibility styles ... Remove in future
                      .regs-docs-fees-cart {
                        width: auto;
                        min-width: 300px;
                        margin-inline-start: 22px;
                        margin-top: var(--wizard-page-menu-top-margin, 3rem);
                        height: 50px;
                        border-radius: @roundedCorners;
                        border: solid 2px @accentColor30;
                        cursor: pointer;
                        color: @accentColor10;
                        box-shadow: inset 50px 0;
                        transition-duration: 0.2s;
                        display: flex;
                        align-items: center;

                        #shoppingcartmini {
                          flex: auto;
                          display: flex;

                          &::before {
                            content: "";
                            display: block;
                            width: 50px;
                            min-width: 50px;
                            height: 34px;
                            background: transparent;
                            position: absolute;
                            top: 0;
                            inset-inline-start: 0;
                          }
                        }
                        &::before {
                          font-family: "Font Awesome 5 Pro";
                          font-weight: 400;
                          -moz-osx-font-smoothing: grayscale;
                          -webkit-font-smoothing: antialiased;
                          display: inline-flex;
                          font-style: normal;
                          font-variant: normal;
                          text-rendering: auto;
                          line-height: 1;
                          content: "\f07a";
                          width: 50px;
                          height: 100%;
                          align-items: center;
                          justify-content: center;
                          color: @accentColor;
                        }
                        span {
                          &.cart-title {
                            &[hidden] {
                              color: @accentColor;
                              font-weight: 600;
                              font-size: 17px;
                              flex: auto;
                              align-items: center;
                              white-space: nowrap;

                              &::after {
                                font-family: "Font Awesome 5 Pro";
                                font-weight: 600;
                                -moz-osx-font-smoothing: grayscale;
                                -webkit-font-smoothing: antialiased;
                                display: inline-flex;
                                font-style: normal;
                                font-variant: normal;
                                text-rendering: auto;
                                line-height: 1;
                                content: "\f106";
                                width: 50px;
                                height: 100%;
                                align-items: center;
                                justify-content: center;
                                color: @accentColor;
                                margin-inline-start: auto;
                                position: absolute;
                                inset-inline-end: 0px;
                              }
                            }
                          }
                        }
                        .docs {
                          display: none;
                        }
                        .regs {
                          line-height: 1;
                          position: relative;
                          padding-inline-end: 1.2rem;
                          border-inline-end: solid 1px @accentColor30;
                          display: flex;
                          align-items: center;
                          margin-inline-start: 1.5rem;
                          padding-top: 2px;
                          padding-bottom: 2px;
                          line-height: 1;
                          height: fit-content;
                          align-self: center;

                          span {
                            &.regs-text {
                              &[hidden] {
                                display: block;
                                color: @accentColor;
                                font-weight: 600;
                                font-size: 15px;
                                margin-inline-end: 6px;
                                white-space: nowrap;
                              }
                            }
                            &.count {
                              background-color: transparent;
                              color: @accentColor;
                              border: solid 1px @accentColor;
                              border-radius: 50%;
                              width: 16px;
                              height: 16px;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              font-weight: 600;
                              line-height: 16px;
                              font-size: 10px;
                            }
                          }
                        }
                        i {
                          display: none;
                        }
                        :where(.currency, .fees) {
                          color: @accentColor;
                          font-size: 15px;
                          font-weight: 600;
                          line-height: 1;
                          align-self: center;
                        }
                        .fees {
                          flex: 1;
                          text-align: center;
                          display: flex;
                          gap: 4px;
                          justify-content: center;
                          padding-inline: 10px;
                        }
                        .currency:empty {
                          display: none;
                        }
                        &.open {
                          border: none;
                          box-shadow: inset 400px 0;
                          border-radius: @roundedCorners @roundedCorners 0 0;
                          height: 50px;

                          #shoppingcartmini {
                            flex: auto;
                          }
                          span {
                            &.cart-title {
                              &[hidden] {
                                display: flex;
                              }
                            }
                          }
                          .regs {
                            display: none;
                          }
                          :where(.no-cost-msg, .fees) {
                            padding-inline-end: 50px;
                          }
                        }
                        @media screen and (max-width: 640px) {
                          min-width: auto;
                          width: auto;

                          #shoppingcartmini {
                            position: absolute;
                            width: 50px;
                            height: 46px;

                            :where(.regs, .docs, .currency, .fees) {
                              display: none;
                            }
                          }
                        }
                      }
                      .regs-docs-fees-drawer {
                        background-color: @whiteColor;
                        border-radius: 0 0 @roundedCorners @roundedCorners;
                        height: auto;
                        position: absolute;
                        inset-inline-end: 0;
                        top: calc(100% - 3rem);
                        z-index: 999;
                        width: calc(28% - 22px);
                        box-shadow: 0px 0px 20px @black5Color;
                        min-width: 300px;
                        max-height: 74vh;
                        overflow-y: auto;
                        display: none;

                        #shoppingcart {
                          display: block !important;
                        }
                        .drawer-section-header {
                          border: none;
                          background: no-repeat;
                          background-image: none;
                          padding: 1rem 2rem;
                          font-size: 16px;
                          font-weight: 600;
                          color: @darkTextColor;
                          border-top: solid 1px @accentColor10;
                          padding-inline-end: 16px;
                          width: 100%;
    
                          /*.section-regs .section-fees .section-docs*/
                          i {
                            border: none;
                            width: 18px;
                            height: 18px;
                            font-weight: 600;
                            font-size: 14px;
                            color: @darkTextColor80;
    
                            &::before {
                              line-height: 16px;
                              content: "\F107";
                            }
                          }
                          &.opened {
                            background-color: @accentColor;
                            color: @whiteColor;
                            
                            i {
                              color: @whiteColor;

                              &::before {
                                content: "\F106";
                              }
                            }
                            &:where(.section-regs, .section-docs) {
                              & > span {
                                & > span {
                                  &:last-child {
                                    color: @accentColor;
                                    background: @whiteColor;
                                  }
                                }
                              }
                            }
                            &.section-fees {
                              span {
                                span {
                                  &:last-child {
                                    color: @whiteColor;
                                  }
                                }
                              }
                            }
                          }
                          &:where(.section-regs, .section-docs) {
                            & > span {
                              & > span {
                                &:last-child {
                                  font-size: 12px;
                                  min-width: 16px;
                                  height: 16px;
                                  font-weight: 600;
                                  background: @accentColor;
                                  border-radius: 8px;
                                  display: flex;
                                  align-items: center;
                                  justify-content: center;
                                  color: @whiteColor;
                                }
                              }
                            }
                          }
                          &.section-docs {
                            &:not(.opened) {
                              border-radius: 0 0 @roundedCorners @roundedCorners;
                            }
                          }
                          &:last-of-type {
                            &:not(.opened) {
                              border-radius: @roundedCorners;
                            }
                          }
                          &:first-of-type {
                            &:not(.opened) {
                              border-top: none;
                            }
                          }
                          & > span {
                            flex: auto;
                            justify-content: space-between;
                            text-align: start;
                            display: flex;
                            align-items: center;
    
                           & > span {
                             &:last-child {
                               margin-inline-end: 1.6rem;
                               /*color: @accentColor;*/
                               font-weight: 600;
                             }
                            }
                          }
                        }
                        .drawer-section-content {
                          padding: 0;
                          display: none;
    
                          &#guidemenurequirements {
                            border-radius: 0 0 @roundedCorners @roundedCorners;
                          }
                          &#guidemenuregs {
                            label {
                              cursor: pointer;
                              position: relative;
                              font-weight: 600;
                              color: @accentColor50;
                              display: flex;
                              align-items: center;

                              input[type="checkbox"] {
                                appearance: none;
                                position: initial;
                                margin: 0;
                                width: auto;
                                height: auto;
  
                                &::after {
                                  font-weight: 400;
                                  content: "\F111";
                                  position: absolute;
                                  font-family: "Font Awesome 5 Pro";
                                  -moz-osx-font-smoothing: grayscale;
                                  -webkit-font-smoothing: antialiased;
                                  display: inline-block;
                                  font-style: normal;
                                  font-variant: normal;
                                  text-rendering: auto;
                                  line-height: 1;
                                  font-size: 16px;
                                  top: -1px;
                                  color: @accentColor50;
                                  inset-inline-end: 0;
                                  cursor: pointer;
                                }
                                &:checked {  
                                  &::after {
                                    color: @accentColor;
                                    content: "\F058";
                                    font-weight: 900;
                                    top: calc(50% - 8px);
                                  }
                                  & + * {
                                    color: @accentColor;
                                  }
                                }
                                & + span {
                                  padding-inline-end: 24px;
                                  line-height: 1.3;
                                }
                              }
                            }
                            > div {
                              padding-inline-end: 16px;
                              
                              &:hover {
                                label {
                                  color: @accentColor;
        
                                  input[type="checkbox"] {    
                                    &::after {
                                      color: @accentColor;
                                    }
                                  }
                                }
                              }
                            }
                          }
                          &#guidemenufees {
                            > div {
                              display: flex;
    
                              span {
                                margin-inline-start: auto;
                              }
                            }
                          }
                          > div {
                            color: @darkTextColor;
                            border-top: solid 1px @accentColor10;
                            font-size: 14px;
                            font-weight: 600;
                            padding: 15px 20px;
                            line-height: 18px;
                            background-color: @accentColor3;
  
                            &:empty {
                              display: none;
                            }
                            &:first-child {
                              border-top: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .no-cost-msg {
      color: @accentColor;
      font-weight: 600;
      font-size: 15px;
      flex: 1;
      padding: 0 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      white-space: nowrap;
    }
  }
}
@-webkit-keyframes colorChange2x {
  0% {
    color: @accentColor;
  }
  100% {
    color: @accentColor10;
  }
}
@keyframes colorChange2x {
  0% {
    color: @accentColor;
  }
  100% {
    color: @accentColor10;
  }
}