body {
    &.modern-theme {
        #base {
            #content {
                .section-body {
                    .contain-lg {
                        :where(.be-list-page, .manage-be-page) {
                            .big-title {
                                font-weight: 600;
                                color: @accentColor;
                            }
                            .list-container {
                                padding: @panelPadding;
                                background: @whiteColor;
                                border: none;
                                border-radius: @roundedCorners;
                                box-shadow: 0px 9px 20px @black2Color;

                                table {
                                    &.business-list-table {
                                        border-collapse: initial;

                                        thead {
                                            opacity: 1;
                                            position: relative;
                                            top: -5px;

                                            tr {
                                                th {
                                                    background-color: @accentColor5;
                                                    text-transform: none;
                                                    color: @accentColor;
                                                    font-size: 14px;
                                                    font-weight: 600;
                                                    padding: 10px 20px;
                                                    white-space: nowrap;
                                                    border: none;

                                                    &.row-toggler {
                                                        padding: 0;
                                                    }
                                                    &:first-child {
                                                        border-radius: @roundedCorners 0 0 @roundedCorners;
                                                    }
                                                    &:last-child {
                                                        border-radius: 0 @roundedCorners @roundedCorners 0;
                                                    }
                                                    .sortorder {
                                                        inset-inline-end: initial;
                                                    }
                                                }
                                            }
                                        }
                                        tbody {
                                            tr {
                                                td {
                                                    transition-duration: 0.2s;

                                                    &:not(.row-toggler) {
                                                        padding: 15px 20px;
                                                    }
                                                    &.row-toggler {
                                                        border-radius: @roundedCorners 0 0 @roundedCorners;

                                                        & + td {
                                                            font-weight: 500;
                                                            font-size: 15px;
                                                            color: @darkTextColor;
                                                        }
                                                    }
                                                    &.date-col {
                                                        font-weight: 600;
                                                        font-size: 12px;
                                                        color: @darkTextColor;
                                                    }
                                                    &:last-child {
                                                        border-radius: 0 @roundedCorners @roundedCorners 0;
                                                    }
                                                }
                                                &:hover {
                                                    td {
                                                        background-color: @black2Color;
                                                    }
                                                }
                                                &.active {
                                                    td {
                                                        background-color: @whiteColor;
                                                        border-top: solid 1px @accentColor20;

                                                        &:first-child {
                                                            border-inline-start: solid 1px @accentColor20;
                                                            border-radius: @roundedCorners 0 0 0;
                                                        }
                                                        &:last-child {
                                                            border-inline-end: solid 1px @accentColor20;
                                                            border-radius: 0 @roundedCorners 0 0;
                                                        }
                                                    }
                                                    & + tr {
                                                        > td {
                                                            border-style: none solid solid;
                                                            border-width: 1px;
                                                            border-color: @accentColor20;
                                                            background-color: @whiteColor !important;
                                                            border-bottom-left-radius: @roundedCorners;
                                                            border-bottom-right-radius: @roundedCorners;

                                                            table {
                                                                tbody {
                                                                    tr {
                                                                        td {
                                                                            background-color: @whiteColor;

                                                                            .label {
                                                                                &.label-status {
                                                                                    font-weight: 600;

                                                                                    &.label-info {
                                                                                        color: @appStatusPending;
                                                                                    }
                                                                                    &.label-default {
                                                                                        color: @appStatusDraft;
                                                                                    }
                                                                                    &.label-warning {
                                                                                        color: @appStatusAction;
                                                                                    }
                                                                                    &.label-success {
                                                                                        color: @appStatusProcessed;
                                                                                    }
                                                                                    &.label-danger {
                                                                                        color: @appStatusRejected;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        &:hover {
                                                                            td {
                                                                                background-color: @black2Color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .editable-page-title-edit-zone {
                                width: clamp(320px, 50%, 100%);

                                input {
                                    height: 40px;
                                    font-weight: 600;
                                    color: @accentColor;
                                    font-size: 24px;
                                    padding-inline-end: 72px;
                                }
                                i {
                                    top: 5px;
                                    padding: 0 12px;
                                    height: 30px;
                                    display: flex;
                                    align-items: center;
                                    transition-duration: 0.2s;
                                    border-radius: @roundedCorners;
                                    cursor: pointer;

                                    &.fa-check {
                                        inset-inline-end: 0;
                                    }
                                    &.fa-times {
                                        inset-inline-end: 38px;
                                    }
                                    &:hover {
                                        background-color: @accentColor10;
                                    }
                                }
                            }
                            table {
                                &.users-list-table {
                                    thead {
                                        opacity: 1;
                                        position: relative;
                                        top: -5px;

                                        tr {
                                            th {
                                                background-color: @accentColor5;
                                                text-transform: none;
                                                color: @accentColor;
                                                font-size: 14px;
                                                font-weight: 600;
                                                padding: 10px 20px;
                                                white-space: nowrap;
                                                border: none;
                                                letter-spacing: 0;

                                                &:first-child {
                                                    border-radius: @roundedCorners 0 0 @roundedCorners;
                                                }
                                                &:last-child {
                                                    border-radius: 0 @roundedCorners @roundedCorners 0;
                                                }
                                                i {
                                                    margin-inline-start: 6px;
                                                }
                                            }
                                        }
                                    }
                                    tbody {
                                        tr {
                                            td {
                                                background-color: @whiteColor;
                                                vertical-align: middle;
                                                transition-duration: 0.2s;

                                                &:first-child {
                                                    border-radius: @roundedCorners 0 0 @roundedCorners;
                                                }
                                                &:last-child {
                                                    border-radius: 0 @roundedCorners @roundedCorners 0;
                                                }
                                            }
                                            &:hover {
                                                td {
                                                    background-color: @black2Color;
                                                }
                                            }
                                        }
                                    }
                                    tfoot {
                                        button {
                                            line-height: 1;
                                            min-height: 37px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}