body {
    &.modern-theme {
        height: auto;
        
        #affixed-menu {
            position: sticky;
            top: 0;
            z-index: 1050;
        }
        .secondary-menu {
            background-color: @accentColor;

            .contain-lg {
                nav {
                    &.secondary-nav-container {
                        display: flex;
                        background: transparent;
                        align-items: center;

                        :where(.part-b-menu-services, .part-b-menu-roles) {
                            :where(.counter, .active-role-files-count) {
                                background: rgba(255, 255, 255, 0.3);
                                border-radius: 20px;
                                padding-inline: 6px;
                                padding-block: 2px;
                                margin-inline-start: 5px;
                                font-size: 10px;
                                height: 15px;
                            }
                        }

                        .counter {
                            top: 1px;
                            position: relative;
                        }

                        .part-b-menu-services {
                            background-color: rgba(0, 0, 0, 0.3);
                        }

                        .part-b-menu-roles {
                            background-color: rgba(255, 255, 255, 0.2);

                            .counter {
                                background: @accentColor70;
                            }

                            &.file-view {
                                .roles-ui-dropdown {
                                    .menu {
                                        .item {
                                            padding: 0 !important;

                                            a {
                                                padding: 14px 16px !important;
                                                display: flex;
                                                justify-content: space-between;
                                                gap: 10px;
                                                align-items: center;
                                                color: black;

                                                &:where(:hover, :active, :focus) {
                                                    text-decoration: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .part-b-menu-title {
                            font-size: 10px;
                            text-transform: uppercase;
                            padding-inline-start: 20px;
                            padding-block-start: 10px;
                            margin-block-end: -6px;
                            line-height: 1;
                            position: relative;
                            z-index: 1002;
                            display: flex;
                            align-items: center;

                            a {
                                display: flex;
                                margin-inline-start: auto;
                                margin-inline-end: 12px;
                                align-items: center;
                                gap: 4px;
                                border-radius: 2px;
                                color: white;

                                &:where(:hover, :active, :focus) {
                                    background-color: rgba(0, 0, 0, 0.2);
                                    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
                                    text-decoration: none;
                                }

                                span {
                                    text-transform: none;
                                }
                            }
                        }

                        ul {
                            &.secondary-nav {
                                li {
                                    a {
                                        background: transparent;
                                        height: 40px;
                                        display: flex;
                                        align-items: center;

                                        &:hover {
                                            background-color: @black10Color;
                                        }
                                    }

                                    &.active {
                                        a {
                                            background-color: @black10Color;
                                        }
                                    }
                                }
                            }
                        }

                        :where(.services-select, .roles-ui-dropdown) {
                            background-color: transparent !important;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            padding-inline-end: 32px !important;
                            padding-inline-start: 18px !important;
                            min-height: 56px !important;
                            min-width: 240px;
                            border-inline-start: solid 1px transparent !important;
                            border-inline-end: solid 1px transparent !important;
                            animation: fadeIn 150ms forwards;
                            margin-top: -24px;
                            padding-top: 22px !important;
                            padding-bottom: 0 !important;
                            border-radius: 0;
                            border: none;
                            float: none;

                            i {
                                &.dropdown {
                                    padding: 0 12px;
                                    inset-inline-end: 0px !important;
                                    margin: 0;
                                    height: 52px;
                                    font-family: 'Font Awesome 5 Pro';
                                    font-weight: 300;
                                    -moz-osx-font-smoothing: grayscale;
                                    -webkit-font-smoothing: antialiased;
                                    font-style: normal;
                                    font-variant: normal;
                                    text-rendering: auto;
                                    line-height: 1;
                                    font-size: 16px;
                                    align-items: center;
                                    display: inline-flex;
                                    color: white;
                                    right: initial;

                                    &::before {
                                        content: "\F107";
                                    }
                                }
                            }

                            input {
                                height: 32px;
                                top: 20px;
                                padding-block: 0;
                                padding-inline-start: 18px !important;
                                padding-inline-end: 34px;
                            }

                            .menu {
                                border: none;
                                border-radius: @roundedCorners !important;
                                max-height: 340px;
                                top: calc(100% + 6px);

                                .item {
                                    text-align: start;
                                    font-size: 14px;
                                    line-height: 1.3;
                                    padding: 14px 16px !important;
                                    font-weight: 400;
                                    opacity: 0.8;

                                    &.active {
                                        opacity: 1;
                                    }

                                    .files-count {
                                        font-size: 90%;
                                    }
                                }
                            }

                            &.active {
                                border: none !important;
                                border-inline-start: solid 1px transparent !important;
                                border-inline-end: solid 1px transparent !important;

                                .text {
                                    color: @whiteColor !important;
                                    opacity: 0.15;
                                }

                                input {
                                    color: @whiteColor;
                                }

                                i {
                                    color: @whiteColor;
                                }
                            }

                            .text {
                                max-width: 300px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                line-height: 2;
                                color: white;

                                span {
                                    font-weight: 400;
                                }
                            }

                            @media screen and (max-width: 1080px) {
                                width: 100%;
                                padding-inline-start: 18px !important;
                                margin-inline-end: 0;
                            }
                        }

                        .roles-ui-dropdown {
                            .text {
                                display: flex;
                                align-items: center;

                                > :where(span, a) {
                                    &:only-child {
                                        display: contents;
                                        color: inherit;
                                    }
                                }
                            }

                            .menu {
                                .item {
                                    >span {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                    }
                                }

                                &.hide-bots {
                                    .item.bot {
                                        display: none;
                                    }

                                    .fa-toggle-off {
                                        display: block;
                                    }

                                    .fa-toggle-on {
                                        display: none;
                                    }
                                }
                            }

                            :where(.text .item-role-name, .active-role-name) {
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            :where(.text .files-count, .active-role-files-count) {
                                height: 14px;
                                line-height: 1;
                                background: @accentColor70;
                                border-radius: 20px;
                                padding-inline: 6px;
                                padding-block: 2px;
                                margin-inline-start: 5px;
                                font-size: 10px;
                            }
                        }

                        .active-role-files-count {
                            height: 15px;
                        }

                        .bots-toggler {
                            display: flex;
                            padding: 14px 16px;
                            align-items: center;
                            flex: 1;
                            cursor: pointer;
                            font-weight: 500;

                            i {
                                color: @accentColor;

                                &.fa-toggle-off {
                                    display: none;
                                }
                            }

                            span {
                                margin-inline-start: 1rem;
                                line-height: 1;
                                height: 100%;
                                align-items: center;
                                display: flex;
                            }

                            &:hover {
                                background: rgba(0, 0, 0, 0.05);
                            }
                        }

                        .bots-toggler-entry {
                            position: sticky;
                            bottom: 0;
                            background-color: white;
                            z-index: 99;
                            border-top: solid 1px #E8EAE9;
                        }

                        .service-bar-user-lang-menu.partb-newformio {
                            &[hidden] {
                                display: block;
                                margin-inline-start: auto;
                                margin-inline-end: -1.5rem;

                                .headerbar-right {
                                    height: 52px;
                                    flex-direction: row-reverse;
                                    min-height: 52px;

                                    ul {
                                        &#language_selector_container {
                                            >li {
                                                &.dropdown-user {
                                                    a {
                                                        &.dropdown-toggle {
                                                            color: @whiteColor;
                                                            padding: 0 1.5rem;
                                                            height: 52px;
                                                            display: flex;
                                                            align-items: center;
                                                            gap: 6px;
                                                            line-height: 1;

                                                            &:hover {
                                                                background: @black10Color;
                                                            }

                                                            i {
                                                                font-size: 16px;
                                                                margin-inline-start: 0.8rem;
                                                            }
                                                        }
                                                    }

                                                    &.open {
                                                        a {
                                                            &.dropdown-toggle {
                                                                background: @black10Color;
                                                            }
                                                        }

                                                        ul {
                                                            &.dropdown-menu {
                                                                background-color: @whiteColor;
                                                                padding: 0;
                                                                inset-inline-end: inherit;
                                                                border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;
                                                                min-width: 100%;

                                                                li {
                                                                    a {
                                                                        color: @accentColor;
                                                                        font-weight: 600;

                                                                        &:hover {
                                                                            color: @darkTextColor;
                                                                            background-color: @pageBgColor;
                                                                        }
                                                                    }

                                                                    &:first-child {
                                                                        a {
                                                                            border-radius: 0 @roundedCorners 0 0;
                                                                        }
                                                                    }

                                                                    &:last-child {
                                                                        a {
                                                                            border-radius: 0 0 @roundedCorners @roundedCorners;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        &#connect {
                                            height: 100%;

                                            li {
                                                &.dropdown {
                                                    &.user {
                                                        height: 100%;
                                                        padding-inline-start: 12px;

                                                        a {
                                                            text-transform: none;
                                                            font-weight: 600;

                                                            &.dropdown-toggle {
                                                                &.left-part {
                                                                    background: transparent;
                                                                    margin: 0;
                                                                    border: none;
                                                                    padding-inline-start: 0;
                                                                    padding-inline-end: 0;
                                                                    border-radius: 0;

                                                                    i {
                                                                        &.user-icon {
                                                                            display: none;
                                                                        }
                                                                    }

                                                                    span {
                                                                        &.user-name-text {
                                                                            padding: 0;
                                                                        }
                                                                    }

                                                                    &:hover {
                                                                        text-decoration: underline;
                                                                    }

                                                                    .user-initials {
                                                                        display: none;
                                                                    }

                                                                    @media screen and (max-width: 768px) {
                                                                        display: block;

                                                                        .user-name-text {
                                                                            display: block;

                                                                            .user-initials {
                                                                                display: block;

                                                                                span {
                                                                                    display: inline;
                                                                                }
                                                                            }

                                                                            .user-fullname {
                                                                                display: none;
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &.user-dropdown-toggle {
                                                                    background: transparent;
                                                                    border: none;
                                                                    padding-inline-end: 1.3rem;
                                                                    padding-inline-start: 1.3rem;
                                                                    border-radius: 0;
                                                                    cursor: pointer;

                                                                    &:hover {
                                                                        background-color: @black10Color;
                                                                    }

                                                                    i {
                                                                        &.angle-icon {
                                                                            margin-inline-end: 0;
                                                                            font-weight: 300;
                                                                            font-size: 16px;
                                                                        }
                                                                    }

                                                                    @media screen and (max-width: 768px) {
                                                                        height: 100%;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        &:where(:hover, .open) {
                                                            background-color: @black10Color;

                                                            a {
                                                                color: @whiteColor;
                                                            }
                                                        }

                                                        &.open {
                                                            a {
                                                                &.dropdown-toggle {
                                                                    &.user-dropdown-toggle {
                                                                        background-color: @black10Color;

                                                                        i {
                                                                            color: @whiteColor;
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            ul {
                                                                &.dropdown-menu {
                                                                    width: 100%;
                                                                    min-width: fit-content;
                                                                    background-color: @whiteColor;
                                                                    color: @accentColor;
                                                                    padding: 0;
                                                                    border-radius: 0 0 @roundedCorners @roundedCorners;

                                                                    li {
                                                                        &.divider {
                                                                            margin: 0;
                                                                            background-color: @accentColor10;
                                                                            height: 1px;

                                                                            &:first-child {
                                                                                display: none;
                                                                            }
                                                                        }

                                                                        a {
                                                                            padding: 7px 20px;
                                                                            font-size: 13px;
                                                                            font-weight: 600;
                                                                            color: @darkTextColor;

                                                                            &:hover {
                                                                                background-color: @black5Color;
                                                                            }
                                                                        }

                                                                        &:last-child {
                                                                            a {
                                                                                border-radius: 0 0 @roundedCorners @roundedCorners !important;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        @media screen and (max-width: 768px) {
                                                            padding: 0 0 0 12px;
                                                            align-items: center;
                                                        }
                                                    }
                                                }

                                                &:not(.dropdown) {
                                                    height: 100%;

                                                    a {
                                                        height: 100%;
                                                        display: flex;
                                                        align-items: center;
                                                        border-radius: 0 !important;
                                                        background: transparent;
                                                        margin: 0 !important;
                                                        padding: 0 2rem;
                                                        text-transform: none;
                                                        font-weight: 600;
                                                        box-shadow: none !important;

                                                        &:hover {
                                                            background-color: @black10Color !important;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                @media screen and (max-width: 1200px) {
                                    margin-inline-end: 0;
                                }
                            }
                        }

                        .responsive-menu-toggler {
                            display: none;
                            background-color: transparent;
                            background-image: none;
                            border: none;
                            font-size: 20px;
                            margin-inline-start: auto;
                            width: 62px;
                            padding: 0;
                            height: 57px;
                            min-width: 62px;
                        }

                        &.partb-menubar {
                            flex-wrap: wrap;
                        }

                        @media screen and (max-width: 1080px) {
                            .all-roles-menu {
                                ul {
                                    &.secondary-nav {
                                        inset-inline-start: 0;
                                    }
                                }
                            }

                            .service-bar-user-lang-menu {
                                &.partb-newformio {
                                    margin-inline-start: auto !important;
                                    margin-inline-end: 8px;
                                }
                            }
                        }

                        @media screen and (max-width: 680px) {
                            .service-bar-user-lang-menu.partb-newformio[hidden] {
                                display: none;

                                &.responsive-open {
                                    display: block;
                                    position: absolute;
                                    background: #4651aa;
                                    inset-inline-end: 8px;
                                    top: calc(100% + 4px);
                                    padding-inline: 10px;
                                    border-radius: 6px;

                                    .headerbar-right {
                                        height: 57px;
                                    }
                                }
                                .headerbar-right ul#language_selector_container > li.dropdown-user {
                                    padding-block: 0;

                                    a.dropdown-toggle {
                                        height: 57px;
                                    }
                                }
                            }
                            .responsive-menu-toggler {
                                display: grid;
                                place-content: center;
                            }
                        }

                        @media screen and (max-width: 640px) {
                            .services-select {
                                margin-inline-end: auto;
                                margin-inline-start: 0;
                                max-height: 200px;
                            }

                            .service-bar-user-lang-menu {
                                &.partb-newformio {
                                    position: relative;

                                    .headerbar-right {
                                        ul {
                                            &#language_selector_container {
                                                li {
                                                    &.dropdown-user {
                                                        padding: 0;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        #base {
            #content {
                .section-body {
                    .contain-lg {
                        >.row {
                            >.col-lg-12 {
                                >.panel {
                                    padding: @panelPadding;
                                    background: @whiteColor;
                                    border: none;
                                    border-radius: @roundedCorners;
                                    box-shadow: 0 0 20px @black5Color;

                                    >.panel-body {
                                        .table-responsive-container {
                                            table {
                                                thead {
                                                    opacity: 1;

                                                    tr {
                                                        &.responsive-sort {
                                                            display: none;

                                                            td {
                                                                padding: 0 0 10px;
                                                                display: flex;
                                                                align-items: center;

                                                                .sorting-label {
                                                                    background: @accentColor5Solid;
                                                                    color: @accentColor;
                                                                    font-weight: 600;
                                                                    padding: 6px 12px;
                                                                    border-radius: @roundedCorners;
                                                                    display: inline-flex;
                                                                    white-space: nowrap;
                                                                    transition-duration: 0.3s;

                                                                    .sort_by_label {
                                                                        &~span {
                                                                            margin-inline-start: 6px;
                                                                            font-weight: 600;
                                                                            position: relative;

                                                                            &::after {
                                                                                content: "\f354";
                                                                                font-family: 'Font Awesome 5 Pro';
                                                                                font-weight: 900;
                                                                                -moz-osx-font-smoothing: grayscale;
                                                                                -webkit-font-smoothing: antialiased;
                                                                                display: inline-block;
                                                                                font-style: normal;
                                                                                font-variant: normal;
                                                                                text-rendering: auto;
                                                                                line-height: 1;
                                                                                font-size: 1.1rem;
                                                                                margin-inline-start: 6px;
                                                                                border: none;
                                                                                opacity: 0.8;
                                                                            }

                                                                            &.reverse-ordering {
                                                                                &::after {
                                                                                    content: "\f357";
                                                                                    color: #4a6288;
                                                                                    font-weight: 900;
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &:hover {
                                                                        background: @accentColor10Solid;
                                                                    }
                                                                }

                                                                .cells-filter-toggler {
                                                                    background: @accentColor5Solid;
                                                                    color: @accentColor;
                                                                    padding: 6px 12px;
                                                                    border-radius: @roundedCorners;
                                                                    transition-duration: 0.3s;
                                                                    display: inline-flex;
                                                                    height: 37px;
                                                                    align-items: center;
                                                                    margin-inline-start: 12px;
                                                                    cursor: pointer;

                                                                    &:hover {
                                                                        background: @accentColor10Solid;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        &:first-child+tr {
                                                            th {
                                                                background-color: @accentColor5;
                                                                text-transform: none;
                                                                color: @accentColor;
                                                                font-size: 14px;
                                                                font-weight: 500;
                                                                padding: 10px 12px;
                                                                border: none !important;

                                                                &:first-child {
                                                                    border-radius: @roundedCorners 0 0 @roundedCorners;
                                                                }

                                                                &:last-child {
                                                                    border-radius: 0 @roundedCorners @roundedCorners 0;
                                                                }

                                                                a {
                                                                    text-transform: none;
                                                                    color: @accentColor;
                                                                    font-size: 14px;
                                                                    letter-spacing: 0;
                                                                }

                                                                .sortorder {
                                                                    top: 0;
                                                                    height: 100%;
                                                                    inset-inline-end: auto;
                                                                    margin-inline-start: 12px;

                                                                    &::after {
                                                                        content: "\f176";
                                                                        font-weight: 600;
                                                                        font-size: 14px;
                                                                        color: @accentColor;
                                                                        top: calc(50% - 7.5px);
                                                                    }

                                                                    &.reverse {
                                                                        &::after {
                                                                            content: "\f175";
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        &.filters {
                                                            td {
                                                                border-top: none;
                                                                padding: 0;

                                                                :where(select, input) {
                                                                    border: solid 1px @accentColor10;
                                                                    background-color: transparent;
                                                                    border-radius: @roundedCorners;
                                                                    margin-top: 18px;
                                                                    height: 34px;
                                                                    box-shadow: none;
                                                                    margin-inline-end: 20px;
                                                                    width: calc(100% - 20px);
                                                                    margin-bottom: 12px;
                                                                    font-weight: 600;
                                                                    font-size: 13px;
                                                                    color: @darkTextColor;
                                                                }

                                                                select {
                                                                    padding-inline-start: 8px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                tbody {
                                                    tr {
                                                        td {
                                                            color: @darkTextColor;
                                                            font-size: 15px;
                                                            padding: 15px 12px;

                                                            span {
                                                                &:where(.label-status, .label) {
                                                                    font-weight: 600;
                                                                    background-color: transparent;

                                                                    &:not(:empty) {
                                                                        display: flex;
                                                                    }

                                                                    &:where(.success, .label-success) {
                                                                        color: @appStatusProcessed;
                                                                    }

                                                                    &:where(.info, .label-info) {
                                                                        color: @appStatusPending;
                                                                    }

                                                                    &:where(.warning, .label-warning) {
                                                                        color: @appStatusAction;
                                                                    }

                                                                    &:where(.danger, .label-danger) {
                                                                        color: @appStatusRejected;
                                                                    }
                                                                }
                                                            }

                                                            &.date-column {
                                                                font-size: 12px;
                                                            }

                                                            file-status {
                                                                display: inline-flex;
                                                                margin-inline-end: 0;
                                                                position: relative;
                                                                top: -1px;

                                                                span {
                                                                    &.label {
                                                                        padding: 3px 5px !important;
                                                                        font-size: 12px;
                                                                        margin-inline-end: 6px;
                                                                        font-weight: 600;
                                                                    }
                                                                }

                                                                &:empty {
                                                                    display: none !important;
                                                                }
                                                            }
                                                        }

                                                        &:nth-child(odd) {
                                                            background-color: transparent;

                                                            td {
                                                                background-color: transparent;
                                                            }
                                                        }

                                                        &:hover {
                                                            td {
                                                                background-color: @darkTextColor2;
                                                            }
                                                        }
                                                    }
                                                }

                                                @media screen and (max-width: 768px) {
                                                    thead {
                                                        display: block !important;
                                                        position: absolute;
                                                        z-index: 99;

                                                        tr {
                                                            &.responsive-sort {
                                                                display: block;

                                                                &+tr {
                                                                    display: none;
                                                                    background-color: @accentColor10Solid;
                                                                    position: absolute;
                                                                    z-index: 999;
                                                                    border-radius: 0 0 @roundedCorners @roundedCorners;
                                                                    transition-duration: 0.3s;
                                                                    overflow: hidden;
                                                                    flex-direction: column;

                                                                    th {
                                                                        flex: 1;
                                                                        width: 100%;
                                                                        background-color: transparent;
                                                                        padding: 0;

                                                                        &:where(.reg-status, :last-child) {
                                                                            display: none;
                                                                        }

                                                                        input {
                                                                            display: none;
                                                                        }

                                                                        >a {
                                                                            display: block;
                                                                            padding: 10px 12px;

                                                                            &:hover {
                                                                                background-color: @accentColor10;
                                                                            }
                                                                        }

                                                                        >span {
                                                                            background-color: transparent;
                                                                            border-radius: 0 !important;
                                                                            padding: 10px 12px;

                                                                            &:hover {
                                                                                background-color: @accentColor10;
                                                                            }
                                                                        }

                                                                        .sortorder {
                                                                            display: none;
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            &.filters {
                                                                display: none;
                                                            }
                                                        }
                                                    }

                                                    &.show-sorting {
                                                        thead {
                                                            tr {
                                                                &.responsive-sort+tr {
                                                                    display: flex;
                                                                    transform: translateY(-10px);
                                                                }

                                                                &.responsive-sort {
                                                                    display: block;

                                                                    td {
                                                                        span {
                                                                            &.sorting-label {
                                                                                border-radius: @roundedCorners @roundedCorners 0 0;
                                                                                background-color: @accentColor10Solid;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    &.show-search {
                                                        thead {
                                                            tr {
                                                                &.filters {
                                                                    display: flex;
                                                                    transform: translateY(-10px);
                                                                    width: 244px;
                                                                    flex-direction: column;
                                                                    background-color: @accentColor10Solid;
                                                                    border-radius: @roundedCorners 0 @roundedCorners @roundedCorners;

                                                                    td {
                                                                        span {
                                                                            display: none;
                                                                        }

                                                                        select {
                                                                            margin: 12px 12px 0;
                                                                            background-color: @whiteColor;
                                                                        }

                                                                        input {
                                                                            display: block;
                                                                            margin: 12px !important;
                                                                            background: @whiteColor;
                                                                            padding-inline-start: 12px;
                                                                            width: calc(100% - 24px);
                                                                        }

                                                                        &:first-child {
                                                                            input {
                                                                                margin: 12px 12px 0 !important;
                                                                            }
                                                                        }

                                                                        &:last-child {
                                                                            display: none;
                                                                        }
                                                                    }
                                                                }

                                                                &.responsive-sort {
                                                                    td {
                                                                        span {
                                                                            &.cells-filter-toggler {
                                                                                border-radius: @roundedCorners @roundedCorners 0 0;
                                                                                background-color: @accentColor10;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    tbody {
                                                        display: grid;
                                                        grid-auto-flow: column;
                                                        max-width: 100%;
                                                        gap: 12px;
                                                        margin-top: 50px;

                                                        tr {
                                                            display: grid;
                                                            border-radius: @roundedCorners;
                                                            border: solid 1px @accentColor10;
                                                            grid-template-rows: auto auto auto auto auto;
                                                            grid-template-columns: 1fr;
                                                            min-width: 240px;

                                                            td {
                                                                width: auto;
                                                                border: none;
                                                                padding-top: 1rem;
                                                                padding-bottom: 1rem;
                                                                font-weight: 600;

                                                                .responsive-label {
                                                                    &[hidden] {
                                                                        display: block;
                                                                        font-size: 12px;
                                                                        font-weight: 400;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .entry-status-legend {
                                                gap: 20px;

                                                >div {
                                                    display: flex;
                                                    align-items: center;
                                                    gap: 5px;

                                                    span {
                                                        line-height: 1;
                                                    }
                                                }
                                            }
                                        }

                                        .table-paging-container {
                                            background-color: transparent;

                                            dir-pagination-controls {
                                                display: flex;
                                            }

                                            ul {
                                                &.pagination {
                                                    background-color: @accentColor5;
                                                    border-radius: @roundedCorners;
                                                    overflow: hidden;
                                                    margin-top: 32px;
                                                    margin-bottom: 0;

                                                    li {
                                                        display: inline-flex;
                                                        margin-inline-end: 1px;

                                                        a {
                                                            background-color: transparent;
                                                            height: 46px;
                                                            display: flex;
                                                            align-items: center;
                                                            border-radius: @roundedCorners;
                                                            color: @darkTextColor;
                                                            font-weight: 600;
                                                            font-size: 12px;
                                                        }

                                                        &:where(.active, :hover) {
                                                            a {
                                                                background-color: @accentColor10;
                                                            }
                                                        }

                                                        &:where(:first-child, :last-child) {
                                                            a {
                                                                color: transparent;
                                                                position: relative;
                                                                width: 38px;

                                                                &::before {
                                                                    font-family: 'Font Awesome 5 Pro';
                                                                    font-weight: 300;
                                                                    -moz-osx-font-smoothing: grayscale;
                                                                    -webkit-font-smoothing: antialiased;
                                                                    display: inline-block;
                                                                    font-style: normal;
                                                                    font-variant: normal;
                                                                    text-rendering: auto;
                                                                    line-height: 1;
                                                                    font-size: 22px;
                                                                    color: @darkTextColor;
                                                                    position: absolute;
                                                                }
                                                            }
                                                        }

                                                        &:first-child {
                                                            a {
                                                                &::before {
                                                                    content: "\f104";
                                                                }
                                                            }
                                                        }

                                                        &:last-child {
                                                            a {
                                                                &::before {
                                                                    content: "\f105";
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        #entreprise-grid {
                                            overflow: hidden;
                                        }

                                        .status-legend-filter {
                                            margin-top: 32px;

                                            .application-status {
                                                margin-inline-end: -2000px;
                                                height: 0;
                                                transition-duration: 0.4s;
                                                transition-timing-function: cubic-bezier(0, 0.18, 0, 1);

                                                .applications-statuses {
                                                    display: flex;

                                                    span {
                                                        color: @darkTextColor;
                                                        font-size: 11px;
                                                        padding: 13px 12px;
                                                        line-height: 20px;

                                                        &.label-status {
                                                            font-weight: 600;
                                                            display: flex;

                                                            &.success {
                                                                color: @appStatusProcessed;
                                                            }

                                                            &.info {
                                                                color: @appStatusPending;
                                                            }

                                                            &.warning {
                                                                color: @appStatusAction;
                                                            }

                                                            &.danger {
                                                                color: @appStatusRejected;
                                                            }

                                                            &:hover {
                                                                box-shadow: inset 0 -1px 0;
                                                            }
                                                        }

                                                        &.active-filter {
                                                            .label-status {
                                                                box-shadow: inset 0 -1px 0;
                                                            }
                                                        }
                                                    }
                                                }

                                                &.show-legend {
                                                    margin-inline-end: 0;
                                                    height: auto;

                                                    @media screen and (max-width: 480px) {
                                                        .applications-statuses {
                                                            display: grid;

                                                            span {
                                                                text-align: start;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .btn-link {
                                                font-size: 12px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        >div {
                            >div {
                                >div {
                                    &.panel {
                                        padding: @panelPadding;
                                        background: @whiteColor;
                                        border: none;
                                        border-radius: @roundedCorners;
                                        box-shadow: 0 0 20px @black5Color;

                                        .panel-body {
                                            table {
                                                thead {
                                                    opacity: 1;

                                                    tr {
                                                        &:first-child {
                                                            th {
                                                                background-color: @accentColor5;
                                                                text-transform: none;
                                                                color: @accentColor;
                                                                font-size: 14px;
                                                                font-weight: 500;
                                                                padding: 10px 12px;
                                                                border: none !important;

                                                                &:first-child {
                                                                    border-radius: @roundedCorners 0 0 @roundedCorners;
                                                                }

                                                                &:last-child {
                                                                    border-radius: 0 @roundedCorners @roundedCorners 0;
                                                                }

                                                                a {
                                                                    text-transform: none;
                                                                    color: @accentColor;
                                                                    font-size: 14px;
                                                                    letter-spacing: 0;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                @media screen and (max-width: 768px) {
                                                    thead {
                                                        display: none;
                                                    }

                                                    tbody {
                                                        display: block;

                                                        tr {
                                                            display: grid;

                                                            td {
                                                                display: flex;
                                                                flex-direction: column;
                                                                text-align: start !important;
                                                                padding-bottom: 1rem;

                                                                &:first-child {
                                                                    padding-top: 1.5rem;
                                                                }

                                                                span {
                                                                    text-align: start;

                                                                    &.partb-file-resume-responsive-table-label {
                                                                        &[hidden] {
                                                                            display: block;
                                                                            color: @accentColor;
                                                                            font-size: 14px;
                                                                            font-weight: 600;
                                                                            letter-spacing: 0.125rem;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                &.part-b-single-file-resume {
                                                    thead {
                                                        tr {
                                                            th {
                                                                &:where(.date-col, .status-col) {
                                                                    width: 200px;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    tbody {
                                                        tr {
                                                            td {
                                                                padding: 15px 12px;
                                                                vertical-align: middle;
                                                                line-height: 1;
                                                                font-size: 15px;

                                                                &.date-col {
                                                                    font-size: 12px;
                                                                }

                                                                span {
                                                                    &.label {
                                                                        &.label-status {
                                                                            font-weight: 500;
                                                                            padding-bottom: 0 !important;

                                                                            &.filepending {
                                                                                color: @appStatusPending;
                                                                            }

                                                                            &.filevalidated {
                                                                                color: @appStatusProcessed;
                                                                            }

                                                                            &.filedecline {
                                                                                color: @appStatusAction;
                                                                            }

                                                                            &.filereject {
                                                                                color: @appStatusRejected;
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                file-status {
                                                                    display: inline-flex;
                                                                    margin-inline-end: 0;
                                                                    position: relative;
                                                                    top: 1px;

                                                                    span {
                                                                        &.label {
                                                                            padding: 3px 5px 3px 0px !important;
                                                                            font-size: 12px;
                                                                            margin-inline-end: 6px;
                                                                            font-weight: 500;
                                                                        }
                                                                    }

                                                                    &:empty {
                                                                        display: none !important;
                                                                    }
                                                                }

                                                                strong {
                                                                    font-weight: 500;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &.row {
                                        .panel {
                                            padding: @panelPadding;
                                            background: @whiteColor;
                                            border: none;
                                            border-radius: @roundedCorners;
                                            box-shadow: 0 0 20px @black5Color;

                                            .panel-heading {
                                                padding: 0 !important;
                                                align-items: center;

                                                h3 {
                                                    padding: 0;
                                                }

                                                .restart-process-box {
                                                    margin-top: 0 !important;

                                                    @media screen and (max-width: 560px) {
                                                        display: grid;
                                                        margin-inline-start: 0 !important;
                                                        grid-template-columns: 1fr 1fr;
                                                        width: 100%;

                                                        button+button {
                                                            margin-inline-end: 0 !important;
                                                        }

                                                        label {
                                                            grid-column-start: 1;
                                                            grid-column-end: 3;
                                                        }
                                                    }
                                                }

                                                @media screen and (max-width: 768px) {
                                                    flex-direction: column;
                                                    align-items: flex-start !important;

                                                    h3 {
                                                        margin-bottom: 12px;
                                                    }
                                                }
                                            }

                                            .panel-body {
                                                padding: 2rem 0 0 !important;

                                                >.row {
                                                    margin: 0;

                                                    >.col-sm-12 {
                                                        padding: 0;
                                                    }
                                                }

                                                @media screen and (max-width: 768px) {
                                                    max-width: calc(100vw - 118px);
                                                }
                                            }
                                        }
                                    }
                                }

                                #tabs-zone {
                                    >.panel {
                                        >.part-b-forms-tabs {
                                            ul {
                                                &.nav.nav-tabs {
                                                    >li {
                                                        box-shadow: none;
                                                        height: auto;

                                                        a {
                                                            font-size: 23px;
                                                            color: @accentColor40;
                                                            font-weight: 600;
                                                            padding-inline-start: 42px;
                                                            padding-inline-end: 42px;
                                                            border-top: none;
                                                            border-top-left-radius: @roundedCorners;
                                                            border-top-right-radius: @roundedCorners;
                                                        }

                                                        &.active {
                                                            a {
                                                                background-color: @accentColor10;
                                                                color: @accentColor;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        >.panel-body {
                                            &.tab-content {
                                                padding: @panelPadding !important;
                                                background: @whiteColor;
                                                border: none;
                                                border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;
                                                box-shadow: 0 0 20px @black5Color;

                                                #form-type-B6 {
                                                    background: transparent;
                                                    box-shadow: none;

                                                    .tab-description {
                                                        margin: 0;
                                                        padding: 0 0 20px;
                                                        line-height: 1;

                                                        span {
                                                            color: @darkTextColor;

                                                            &.tab-description-back-to-list {
                                                                &::before {
                                                                    top: 0;
                                                                    line-height: 15px;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    .bg-gray {
                                                        background-color: transparent;

                                                        &.px-2 {
                                                            &.pb-1 {
                                                                padding: 0 !important;
                                                            }
                                                        }
                                                    }

                                                    .document-revision-list {
                                                        .validation-headers {
                                                            form {
                                                                &.formio-form {
                                                                    .form-group {
                                                                        &.form-field-type-columns {
                                                                            margin-top: 0;

                                                                            .form-field-type-button {
                                                                                button {
                                                                                    background-color: transparent;

                                                                                    &:hover {
                                                                                        box-shadow: none !important;
                                                                                    }
                                                                                }
                                                                            }

                                                                            .form-field-type-mycounter {
                                                                                span {
                                                                                    &.bullet {
                                                                                        font-size: 13px;
                                                                                        display: block;
                                                                                        line-height: 1.3em;
                                                                                        background: @accentColor;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    #carouselRevision {
                                                        .carousel-inner {
                                                            .item {
                                                                .documents-validator {
                                                                    .carousel-selected-index {
                                                                        inset-inline-start: 80px;
                                                                    }

                                                                    form {
                                                                        >.form-group {
                                                                            &.form-field-type-panel {
                                                                                margin-top: 0;

                                                                                >.panel {
                                                                                    box-shadow: none !important;

                                                                                    >.panel-heading {
                                                                                        margin: 0;
                                                                                        border-radius: @roundedCorners;
                                                                                    }

                                                                                    >.panel-body {
                                                                                        padding: 0;
                                                                                        box-shadow: none;

                                                                                        .formio-component-rejectionrow {
                                                                                            margin: 2rem 0 !important;

                                                                                            .form-field-type-checkbox {
                                                                                                &.check-switch {
                                                                                                    .checkbox {
                                                                                                        margin-top: 15px;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                .carousel-controls {
                                                                    .carousel-control {
                                                                        &.left {
                                                                            inset-inline-start: 0;
                                                                            border-radius: @roundedCorners 0 0 @roundedCorners;
                                                                        }

                                                                        &.right {
                                                                            inset-inline-start: 34px;
                                                                        }
                                                                    }
                                                                }

                                                                .img-thumbnail {
                                                                    object {
                                                                        display: block;
                                                                    }
                                                                }

                                                                .revision-doc-info-column {
                                                                    form {
                                                                        >.form-group {
                                                                            &.form-field-type-panel {
                                                                                &:first-of-type {
                                                                                    margin-top: 20px;
                                                                                }

                                                                                >.panel {
                                                                                    box-shadow: none !important;

                                                                                    >.panel-body {
                                                                                        padding: 1rem;
                                                                                        box-shadow: none !important;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &.active {
                                                                    &.single-doc {
                                                                        .carousel-selected-index {
                                                                            inset-inline-start: 23px !important;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                .part-b-tabs-tab-data {
                                                    margin: -35px;
                                                    background: transparent;
                                                    box-shadow: none;
                                                    border-radius: @roundedCorners;
                                                    overflow: hidden;

                                                    .zone_revision {
                                                        .revision-block {
                                                            form {
                                                                .form-group {
                                                                    &.form-field-type-mytabs {
                                                                        margin-top: 0;

                                                                        .tabs-above {
                                                                            .nav-tabs {
                                                                                background-color: transparent;

                                                                                li {
                                                                                    &.active {
                                                                                        a {
                                                                                            background-color: transparent;
                                                                                            box-shadow: inset 0 -2px 0 @accentColor !important;
                                                                                        }
                                                                                    }

                                                                                    a {
                                                                                        color: @accentColor;
                                                                                        text-decoration: none;
                                                                                        font-weight: 600;
                                                                                        padding: 0 35px;
                                                                                    }

                                                                                    &:hover {
                                                                                        a {
                                                                                            border-bottom: none;
                                                                                            box-shadow: inset 0 -2px 0 @accentColor !important;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            .tab-content {
                                                                                .tab-pane {
                                                                                    .card {
                                                                                        .card-body {
                                                                                            >.form-field-type-panel {
                                                                                                box-shadow: none;
                                                                                                margin-top: 0;
                                                                                                margin-bottom: 0 !important;

                                                                                                >.panel {
                                                                                                    background-color: transparent;
                                                                                                    padding: 0 35px;

                                                                                                    >.panel-heading {
                                                                                                        padding-inline-start: 0;
                                                                                                        padding-inline-end: 0;
                                                                                                    }

                                                                                                    >.panel-body {
                                                                                                        padding-inline-start: 0;
                                                                                                        padding-inline-end: 0;

                                                                                                        .form-field-type-panel {
                                                                                                            background-color: @whiteColor;
                                                                                                            box-shadow: none;
                                                                                                            padding: 0;

                                                                                                            .panel {
                                                                                                                padding: 0;
                                                                                                                background-color: @whiteColor;

                                                                                                                .panel-heading {
                                                                                                                    background-color: @whiteColor;

                                                                                                                    h3 {
                                                                                                                        &.panel-title {
                                                                                                                            font-weight: 600;
                                                                                                                            text-transform: none;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }

                                                                                                                .panel-body {
                                                                                                                    .form-field-type-mycardtable {
                                                                                                                        table {
                                                                                                                            tbody {
                                                                                                                                tr {
                                                                                                                                    td {
                                                                                                                                        h2 {
                                                                                                                                            margin: 0;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                            &:last-of-type {
                                                                                                                >.panel {
                                                                                                                    >.panel-body {
                                                                                                                        .form-field-type-mycardtable {
                                                                                                                            &:last-of-type {
                                                                                                                                margin-bottom: 3rem;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                        >.form-field-type-mycardtable {
                                                                                                            &:last-of-type {
                                                                                                                margin-bottom: 35px;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                &.small-padding {
                                                                                                    padding: 0;
                                                                                                }

                                                                                                &+.form-field-type-button {
                                                                                                    display: none;
                                                                                                }
                                                                                            }

                                                                                            >.form-field-type-button {
                                                                                                display: none;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                >.form-field-type-button {
                                                                    margin-top: 11px !important;
                                                                    margin-inline-end: 35px !important;

                                                                    button {
                                                                        line-height: 1;

                                                                        &:hover {
                                                                            box-shadow: none;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    #callout-data {
                                                        form {
                                                            .form-field-type-table {
                                                                margin-top: 35px !important;
                                                                margin-bottom: 0 !important;
                                                                margin-inline-start: 35px !important;
                                                                margin-inline-end: 0 !important;

                                                                tr {
                                                                    display: flex;
                                                                    flex-direction: row-reverse;
                                                                    width: 100%;
                                                                    flex: auto;
                                                                    gap: 2rem;

                                                                    td {
                                                                        padding: 0;

                                                                        .form-field-type-myswitch {
                                                                            &.formio-component-FORMDATAVALIDATIONSTATUS {
                                                                                margin-top: 0 !important;
                                                                            }
                                                                        }

                                                                        &:first-child {
                                                                            flex: auto;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                #form-type-role {
                                                    div {
                                                        &[form="conclusion"] {
                                                            padding: 0 !important;
                                                            background: transparent;
                                                            box-shadow: none !important;

                                                            .form-field-type-columns {
                                                                margin: 0 !important;
                                                            }
                                                        }
                                                    }

                                                    .processing-tab {
                                                        form {
                                                            box-shadow: none;
                                                            background: transparent;

                                                            .form-field-type-hidden {
                                                                &:first-of-type {
                                                                    &+.form-field-type-panel {
                                                                        margin-top: 0;
                                                                    }
                                                                }
                                                            }

                                                            .form-field-type-panel {
                                                                >.panel {
                                                                    box-shadow: none !important;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                #documents {
                                                    background: transparent;
                                                    box-shadow: none;
                                                    padding: 0 !important;
                                                }
                                            }
                                        }
                                    }
                                }

                                .new-formio-part-b {
                                    .formio-component-modal-wrapper,
                                    .formio-component-form {
                                        z-index: 999;

                                        .formio-form {
                                            .formio-component-tabs {
                                                .card {

                                                    .card-body {
                                                        &.tab-pane {

                                                            &:nth-child(2) {
                                                                &.active {
                                                                    border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;
                                                                }
                                                            }

                                                            >.formio-component-part-b-data-tab {
                                                                &~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) {
                                                                    .card {
                                                                        background-color: transparent;

                                                                        .card-header {
                                                                            padding: 0 0 2.5rem;
                                                                            background-color: transparent;
                                                                        }

                                                                        .card-body {
                                                                            padding: 0;

                                                                            .formio-component-rejectionrow {
                                                                                margin: 0 !important;

                                                                                .formio-component-checkbox {
                                                                                    .form-check {
                                                                                        margin-top: 0;

                                                                                        label {
                                                                                            span {
                                                                                                font-weight: 600;
                                                                                                font-size: 15px;
                                                                                                line-height: 12px;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }

                                                                                .formio-component-select {
                                                                                    label {
                                                                                        font-weight: 600 !important;
                                                                                        color: @darkTextColor70;
                                                                                        font-size: 13px;
                                                                                    }

                                                                                    .formio-choices {
                                                                                        .selection {
                                                                                            &.dropdown {
                                                                                                border: solid 1px @borderColor;
                                                                                                border-radius: @roundedCorners !important;
                                                                                                height: @fieldHeight;

                                                                                                &:where(:hover, :focus) {
                                                                                                    outline: none;
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        &.is-open {
                                                                                            .selection {
                                                                                                &.dropdown {
                                                                                                    border-radius: @roundedCorners @roundedCorners 0 0 !important;
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        &::after {
                                                                                            color: @accentColor40;
                                                                                            content: "\F078";
                                                                                            font-family: 'Font Awesome 5 Pro';
                                                                                            font-weight: 300;
                                                                                            -moz-osx-font-smoothing: grayscale;
                                                                                            -webkit-font-smoothing: antialiased;
                                                                                            display: inline-block;
                                                                                            font-style: normal;
                                                                                            font-variant: normal;
                                                                                            text-rendering: auto;
                                                                                            font-size: 16px;
                                                                                            width: auto;
                                                                                            border: none;
                                                                                            margin-top: 0;
                                                                                            height: 8px;
                                                                                            line-height: 7px;
                                                                                            top: calc(50% - 4px);
                                                                                        }

                                                                                        &:hover {
                                                                                            &::after {
                                                                                                color: @accentColor;
                                                                                            }
                                                                                        }

                                                                                        .choices__list {
                                                                                            &.choices__list--dropdown {
                                                                                                border: solid 1px @borderColor;
                                                                                                border-radius: 0 0 @roundedCorners @roundedCorners;

                                                                                                input {
                                                                                                    &.choices__input {
                                                                                                        border-radius: 0 !important;
                                                                                                    }
                                                                                                }

                                                                                                .choices__item {
                                                                                                    &.choices__item--choice {
                                                                                                        padding: 10px 20px;
                                                                                                        font-size: 12px;
                                                                                                        font-weight: 600;
                                                                                                        color: @darkTextColor;

                                                                                                        &:where(.is-selected, :hover) {
                                                                                                            background-color: @accentColor5;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            .formio-component-panel {
                                                                                .card {
                                                                                    .card-body {
                                                                                        >.formio-component-checkbox {
                                                                                            display: none;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &:last-child {
                                                                        .card {
                                                                            margin-bottom: 0 !important;
                                                                        }
                                                                    }
                                                                }

                                                                &+.formio-component-button {
                                                                    &[class*="print"] {
                                                                        margin: 2rem 0 0;
                                                                        display: flex;
                                                                        justify-content: flex-end;

                                                                        button {
                                                                            border-radius: @roundedCorners !important;
                                                                            background-color: @accentColor;
                                                                            padding-inline-start: 25px;
                                                                            padding-inline-end: 25px;
                                                                            font-weight: 600;
                                                                            text-transform: none;
                                                                            position: relative;
                                                                            z-index: 1;
                                                                            height: 26px;
                                                                            padding-top: 0;
                                                                            padding-bottom: 0;
                                                                            font-size: 12px;

                                                                            &:hover {
                                                                                box-shadow: 0 9px 20px @accentColor40 !important;
                                                                                opacity: 0.9;
                                                                            }
                                                                        }

                                                                        &+.formio-component {
                                                                            &:where(.formio-component-table, .formio-component-container > div > .formio-component-table) {
                                                                                &.formio-component-validationtable {
                                                                                    margin: 0 !important;

                                                                                    table {
                                                                                        &.table {
                                                                                            tbody {
                                                                                                tr {
                                                                                                    td {
                                                                                                        &:first-child {
                                                                                                            flex: 2;

                                                                                                            .formio-component-htmlelement {
                                                                                                                .formio-component-checkbox {
                                                                                                                    &.check-switch {
                                                                                                                        margin-top: calc(-4rem + 5px);
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &+.formio-component-container {
                                                                        &.formio-component-previewcontainer {
                                                                            margin-top: calc(-2rem - 3px);

                                                                            &+.formio-component {
                                                                                &.formio-component-panel {
                                                                                    margin-top: 3rem;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &~.formio-component-container>div>.formio-component-panel {

                                                                    .card,
                                                                    &~.formio-component.formio-component-panel .card {
                                                                        background-color: transparent;
                                                                        border: none;
                                                                        margin-top: 0;

                                                                        .card-header {
                                                                            padding: 0;
                                                                            margin: 0;
                                                                        }

                                                                        >.card-body {
                                                                            &:not(:first-child) {
                                                                                padding-top: 1rem !important;
                                                                            }

                                                                            .formio-component-panel {
                                                                                .card-header {
                                                                                    &+.card-body {
                                                                                        .formio-component-panel {
                                                                                            .card {
                                                                                                margin: 0;

                                                                                                .card-body {
                                                                                                    &:first-child {
                                                                                                        padding-top: 0 !important;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &:last-child {
                                                                        margin-bottom: 0;
                                                                    }
                                                                }

                                                                &~.formio-component {

                                                                    &.formio-component-table,
                                                                    &.formio-component-container>div>.formio-component-table {
                                                                        &.formio-component-validationtable {
                                                                            margin: 0 !important;

                                                                            table {
                                                                                &.table {
                                                                                    table-layout: auto;

                                                                                    tbody {
                                                                                        tr {
                                                                                            td {
                                                                                                &:first-child {
                                                                                                    flex: 2;
                                                                                                    padding: 0;
                                                                                                    width: 90%;

                                                                                                    .formio-component-htmlelement {
                                                                                                        margin-top: 6px;

                                                                                                        .formio-component-checkbox {
                                                                                                            &.check-switch {
                                                                                                                margin-top: 0;

                                                                                                                >div {
                                                                                                                    &:first-child {
                                                                                                                        margin-top: 0;
                                                                                                                        margin-inline-end: 32px;

                                                                                                                        &+div {
                                                                                                                            flex: auto;

                                                                                                                            textarea {
                                                                                                                                width: 100%;
                                                                                                                                min-height: 64px;
                                                                                                                                padding: 4px 10px;
                                                                                                                                border: solid 1px @borderColor;
                                                                                                                                border-radius: @roundedCorners !important;

                                                                                                                                &:where(:hover, :focus) {
                                                                                                                                    outline: none;
                                                                                                                                }

                                                                                                                                &::-webkit-resizer {
                                                                                                                                    display: none;
                                                                                                                                }
                                                                                                                            }

                                                                                                                            &.required {
                                                                                                                                label {
                                                                                                                                    &::after {
                                                                                                                                        content: " ";
                                                                                                                                        display: inline-block;
                                                                                                                                        background-color: @errorColor70;
                                                                                                                                        vertical-align: unset;
                                                                                                                                        width: 6px;
                                                                                                                                        height: 6px;
                                                                                                                                        border-radius: 5px;
                                                                                                                                        position: relative;
                                                                                                                                        top: -1px;
                                                                                                                                        inset-inline-start: 2px;
                                                                                                                                        min-width: 6px;
                                                                                                                                    }
                                                                                                                                }

                                                                                                                                textarea {
                                                                                                                                    color: @darkTextColor;

                                                                                                                                    &:empty {
                                                                                                                                        border-color: @errorColor70 !important;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }

                                                                                                                        &.form-check {
                                                                                                                            display: flex;
                                                                                                                            align-items: center;
                                                                                                                            gap: 12px;

                                                                                                                            >span {
                                                                                                                                font-size: 18px;
                                                                                                                                font-weight: 600;
                                                                                                                                color: @warningNotice;
                                                                                                                                line-height: 1;
                                                                                                                            }

                                                                                                                            &.mt-3 {
                                                                                                                                margin-top: 0 !important;
                                                                                                                            }

                                                                                                                            &.mb-3 {
                                                                                                                                margin-bottom: 0 !important;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }

                                                                                                                @media screen and (max-width: 768px) {
                                                                                                                    flex-direction: column;

                                                                                                                    >div {
                                                                                                                        &#datarejectionreason {
                                                                                                                            display: flex;
                                                                                                                            flex-direction: column;
                                                                                                                            margin-top: 3rem;
                                                                                                                            width: 200%;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }

                                                                                                    &~td {
                                                                                                        flex: 1;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            @media screen and (max-width: 768px) {
                                                                                overflow: visible;
                                                                                border: none;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                
                                                                & + .formio-component-container {
                                                                    margin-top: 0;
                                                                }
                                                            }

                                                            >.formio-component-part-b-documents-tab {
                                                                &+.formio-component-panel {
                                                                    .card {
                                                                        background-color: transparent;

                                                                        .card-header {
                                                                            padding: 0;
                                                                            background-color: transparent;
                                                                        }

                                                                        .card-body {
                                                                            padding: 2.7rem 0 0 !important;

                                                                            .formio-component-textarea {
                                                                                .card-body {
                                                                                    padding-top: 0 !important;
                                                                                }
                                                                            }

                                                                            textarea {
                                                                                &.required {
                                                                                    color: @darkTextColor;

                                                                                    &:empty {
                                                                                        border-color: @errorColor70 !important;
                                                                                    }

                                                                                    &::after {
                                                                                        content: " ";
                                                                                        display: inline-block;
                                                                                        background-color: @errorColor70;
                                                                                        vertical-align: unset;
                                                                                        width: 6px;
                                                                                        height: 6px;
                                                                                        border-radius: 5px;
                                                                                        position: relative;
                                                                                        top: -1px;
                                                                                        inset-inline-start: 2px;
                                                                                        min-width: 6px;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        .carousel-inner {
                                                                            .block-previewcontainer {
                                                                                .card-body {
                                                                                    padding-top: 1rem !important;
                                                                                }
                                                                                .formio-component-textarea {
                                                                                    .card-body {
                                                                                        padding-top: 0 !important;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &:last-child {
                                                                        .card {
                                                                            margin-bottom: 0 !important;

                                                                            &::after {
                                                                                display: none;
                                                                            }
                                                                        }
                                                                    }

                                                                    &[class*="formio-component-documentTab"] {
                                                                        margin-top: 0;

                                                                        >.card {
                                                                            >.card-body {
                                                                                padding-top: 0 !important;

                                                                                >.formio-component-panel {
                                                                                    >.card {
                                                                                        >.card-body {
                                                                                            padding-top: 0 !important;

                                                                                            >.formio-component-htmlelement {
                                                                                                margin-top: 0;

                                                                                                .validation-headers {
                                                                                                    .form-field-type-columns {
                                                                                                        .row {
                                                                                                            align-items: center;
                                                                                                            line-height: 1;
                                                                                                            transition-duration: 0.3s;

                                                                                                            .col-sm-1 {
                                                                                                                flex: 0;
                                                                                                                width: auto;

                                                                                                                .form-field-type-mycounter {
                                                                                                                    .bullet {
                                                                                                                        font-size: 14px;
                                                                                                                        line-height: 17px;
                                                                                                                        display: block;
                                                                                                                        background-color: @accentColor;
                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                            .col-sm-8 {
                                                                                                                flex: auto;
                                                                                                                z-index: 2;

                                                                                                                .form-field-type-button {
                                                                                                                    button {
                                                                                                                        background: transparent;
                                                                                                                        box-shadow: none !important;
                                                                                                                        font-weight: 600;
                                                                                                                        text-transform: none;
                                                                                                                        width: calc(100% + 110px);
                                                                                                                        z-index: 9;
                                                                                                                        display: flex;
                                                                                                                        max-width: calc(100% + 110px);
                                                                                                                        padding-inline-end: 110px;

                                                                                                                        &:where(:hover, :focus) {
                                                                                                                            color: initial;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                            .col-sm-3 {
                                                                                                                z-index: 1;
                                                                                                                min-width: fit-content;

                                                                                                                .form-field-type-htmlelement {
                                                                                                                    margin: 0;
                                                                                                                    line-height: 1;

                                                                                                                    .label {
                                                                                                                        display: inline-block;
                                                                                                                        padding: 5px 8px 2px;
                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                            &:hover {
                                                                                                                background: @accentColor10;
                                                                                                                padding: 0 15px;
                                                                                                            }

                                                                                                            @media screen and (max-width: 768px) {
                                                                                                                flex-direction: row;

                                                                                                                >div {
                                                                                                                    &+div {
                                                                                                                        margin-top: 0;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }

                                                                                                    &.document-open {
                                                                                                        .tab-description-back-to-list {
                                                                                                            display: flex;
                                                                                                            margin-bottom: 1rem;
                                                                                                        }

                                                                                                        >.form-field-type-columns {
                                                                                                            display: none;
                                                                                                        }

                                                                                                        &+.carousel {
                                                                                                            &#carouselRevision {
                                                                                                                display: block;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                .tab-description-back-to-list {
                                                                                                    font-size: 18px;
                                                                                                    align-items: center;
                                                                                                    cursor: pointer;
                                                                                                    border-radius: 6px;
                                                                                                    display: flex;
                                                                                                    height: 34px;
                                                                                                    min-width: 40px;
                                                                                                    transition-duration: 0.15s;
                                                                                                    margin-inline-end: -12px;

                                                                                                    &:hover {
                                                                                                        background: @accentColor10;
                                                                                                        color: #222222;
                                                                                                        padding-inline-start: 12px;
                                                                                                    }

                                                                                                    i {
                                                                                                        display: block;
                                                                                                        width: 16px;
                                                                                                    }
                                                                                                }

                                                                                                .carousel {
                                                                                                    &#carouselRevision {
                                                                                                        display: none;

                                                                                                        .carousel-inner {
                                                                                                            .carousel-item {
                                                                                                                flex-wrap: wrap;
                                                                                                                gap: 3rem 0;
                                                                                                                align-items: center;

                                                                                                                >.row {
                                                                                                                    &:first-child {
                                                                                                                        flex: initial;
                                                                                                                        max-width: 50%;
                                                                                                                        padding-inline-end: 20px;
                                                                                                                    }

                                                                                                                    &:last-child {
                                                                                                                        flex: 0 0 100%;
                                                                                                                    }
                                                                                                                }

                                                                                                                .documents-validator {
                                                                                                                    min-height: 37px;
                                                                                                                    align-items: center;
                                                                                                                    display: flex;
                                                                                                                    gap: 8px;
                                                                                                                    font-weight: 500;
                                                                                                                    font-size: 18px;
                                                                                                                    line-height: 1.3;

                                                                                                                    .carousel-selected-index {
                                                                                                                        inset-inline-start: 0;
                                                                                                                        top: 0;
                                                                                                                        position: relative;
                                                                                                                    }
                                                                                                                }

                                                                                                                >.row {
                                                                                                                    .formio-component-checkbox {
                                                                                                                        &.check-switch {
                                                                                                                            margin-top: 0;

                                                                                                                            .checkbox {
                                                                                                                                input {
                                                                                                                                    &[type="checkbox"] {
                                                                                                                                        &+span {
                                                                                                                                            &::before {
                                                                                                                                                top: 0;
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }

                                                                                                                            .form-check {
                                                                                                                                display: flex;
                                                                                                                                align-items: center;
                                                                                                                                gap: 12px;

                                                                                                                                &.mt-3 {
                                                                                                                                    margin-top: 0 !important;
                                                                                                                                }

                                                                                                                                &.mb-3 {
                                                                                                                                    margin-bottom: 0 !important;
                                                                                                                                }

                                                                                                                                >span {
                                                                                                                                    font-size: 18px;
                                                                                                                                    font-weight: 600;
                                                                                                                                    color: @warningNotice;
                                                                                                                                    line-height: 1;
                                                                                                                                }
                                                                                                                            }

                                                                                                                            textarea {
                                                                                                                                border: solid 1px @borderColor;
                                                                                                                                border-radius: @roundedCorners !important;

                                                                                                                                &:where(:hover, :focus) {
                                                                                                                                    outline: none;
                                                                                                                                }

                                                                                                                                &::-webkit-resizer {
                                                                                                                                    display: none;
                                                                                                                                }
                                                                                                                            }

                                                                                                                            label {
                                                                                                                                width: 100%;
                                                                                                                                max-width: 320px;

                                                                                                                                span {
                                                                                                                                    font-weight: 600;
                                                                                                                                    font-size: 16px;
                                                                                                                                    margin-bottom: 7px;
                                                                                                                                    display: block;
                                                                                                                                    margin-top: 2px;
                                                                                                                                }
                                                                                                                            }

                                                                                                                            select {
                                                                                                                                border: solid 1px @borderColor;
                                                                                                                                border-radius: @roundedCorners !important;
                                                                                                                                height: 34px;
                                                                                                                                padding: 0 6px;
                                                                                                                                width: 100%;
                                                                                                                                max-width: 320px;

                                                                                                                                &:focus {
                                                                                                                                    outline: none;
                                                                                                                                    box-shadow: none;
                                                                                                                                }
                                                                                                                            }

                                                                                                                            >div {
                                                                                                                                &:first-child {
                                                                                                                                    margin-top: 8px;
                                                                                                                                    margin-inline-end: 32px;

                                                                                                                                    &+div {
                                                                                                                                        flex: auto;
                                                                                                                                        display: flex;
                                                                                                                                        flex-direction: column;
                                                                                                                                        gap: 1rem;

                                                                                                                                        textarea {
                                                                                                                                            flex: 1 1 auto;
                                                                                                                                            padding: 4px 10px;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }

                                                                                                                            @media screen and (max-width: 768px) {
                                                                                                                                flex-direction: column;

                                                                                                                                >div {
                                                                                                                                    &+div {
                                                                                                                                        margin-top: 2rem;

                                                                                                                                        select {
                                                                                                                                            width: 100%;
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }

                                                                                                                    >.col-md-6 {
                                                                                                                        &:last-child {
                                                                                                                            .formio-component {
                                                                                                                                &.formio-component-panel {
                                                                                                                                    &:first-child {
                                                                                                                                        margin-top: 0;
                                                                                                                                    }

                                                                                                                                    >.card {
                                                                                                                                        border: none;
                                                                                                                                        margin-top: 0;

                                                                                                                                        >.card-body {
                                                                                                                                            >.formio-component-panel {
                                                                                                                                                .card {
                                                                                                                                                    margin: 0;
                                                                                                                                                    border: none;

                                                                                                                                                    .card-header {
                                                                                                                                                        margin: 0 0 1rem;
                                                                                                                                                        background-color: transparent;
                                                                                                                                                    }

                                                                                                                                                    .card-body {
                                                                                                                                                        padding-top: 0 !important;

                                                                                                                                                        .formio-component-panel {
                                                                                                                                                            &:first-child {
                                                                                                                                                                .card {
                                                                                                                                                                    .card-body {
                                                                                                                                                                        padding-top: 0 !important;
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                &.readonly-forms-block {
                                                                                                                                                    .card .card-body {
                                                                                                                                                        padding-top: 1rem !important;
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }

                                                                                                                            .block-previewcontainer {
                                                                                                                                flex: 1;

                                                                                                                                >.formio-component-container {
                                                                                                                                    &:first-child {
                                                                                                                                        margin-top: 0;
                                                                                                                                    }
                                                                                                                                }

                                                                                                                                &:empty {
                                                                                                                                    display: none;
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }

                                                                                                                    &:nth-child(3) {
                                                                                                                        &~.row {
                                                                                                                            margin-top: 3rem;
                                                                                                                        }
                                                                                                                    }

                                                                                                                    @media screen and (max-width: 991px) {
                                                                                                                        flex-direction: column;
                                                                                                                    }

                                                                                                                    &:last-child {
                                                                                                                        margin: 0;
                                                                                                                        gap: 3rem;

                                                                                                                        &::after,
                                                                                                                        &::before {
                                                                                                                            display: none;
                                                                                                                        }

                                                                                                                        >.col-md-6 {
                                                                                                                            padding: 0;
                                                                                                                            flex: 1;

                                                                                                                            &:last-child {
                                                                                                                                display: contents;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }

                                                                                                                &.item {
                                                                                                                    .img-thumbnail {
                                                                                                                        img {
                                                                                                                            margin: 0 auto;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }

                                                                                                                &:where(.active, .prev, .next, .right, .left) {
                                                                                                                    display: flex;
                                                                                                                }

                                                                                                                .readonly-form-columns {
                                                                                                                    &.type-file {
                                                                                                                        .col-md-10 {
                                                                                                                            width: 100%;
                                                                                                                            border-inline-end: none;
                                                                                                                        }
                                                                                                                        .col-md-6 {
                                                                                                                            display: none;
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }

                                                                                                            .carousel-control {
                                                                                                                display: flex;
                                                                                                                align-items: center;
                                                                                                                justify-content: center;
                                                                                                                position: relative;
                                                                                                                width: 40px;
                                                                                                                min-width: 40px;
                                                                                                                transition-duration: 0.15s;
                                                                                                                border-radius: @roundedCorners;

                                                                                                                span {
                                                                                                                    display: block;
                                                                                                                    font-size: 34px;
                                                                                                                    color: #333333;
                                                                                                                    text-shadow: none;
                                                                                                                    width: 100%;
                                                                                                                }

                                                                                                                &:hover {
                                                                                                                    background: @accentColor10;
                                                                                                                    padding: 0 10px;
                                                                                                                    color: #222222;
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &+.formio-component-table {
                                                                    &.formio-component-validationtable {
                                                                        margin: 0 !important;
                                                                    }
                                                                }
                                                            }

                                                            >.formio-component {
                                                                margin-top: 2rem;

                                                                &.formio-component-applicantPaymentFees {
                                                                    .card {
                                                                        background-color: transparent;

                                                                        .card-header {
                                                                            padding: 0;
                                                                        }

                                                                        .card-body {
                                                                            padding-top: 2.5rem !important;

                                                                            .formio-component-columns {
                                                                                margin-top: 0;

                                                                                .formio-component-htmlelement {
                                                                                    margin-top: 0;
                                                                                }

                                                                                &.formio-component-totalPrice {
                                                                                    align-items: center;

                                                                                    .formio-component-htmlelement {
                                                                                        h4 {
                                                                                            font-weight: 600;
                                                                                        }

                                                                                        p {
                                                                                            span {
                                                                                                font-weight: 600;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &.formio-component-applicantPaymentPayments {
                                                                    margin-bottom: 0;

                                                                    .card {
                                                                        background-color: transparent;
                                                                        margin-bottom: 0 !important;

                                                                        .card-header {
                                                                            padding: 0;
                                                                        }

                                                                        .card-body {
                                                                            padding-top: 2.5rem !important;

                                                                            .formio-component-columns {
                                                                                .col-md-1 {
                                                                                    display: none;
                                                                                }

                                                                                .col-md-11 {
                                                                                    padding-inline-start: 0;
                                                                                }

                                                                                .formio-component-checkbox {
                                                                                    input {
                                                                                        &[disabled] {
                                                                                            display: none;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &+.formio-component-button {
                                                                        display: none;
                                                                    }
                                                                }

                                                                &.formio-component-panel {
                                                                    .card {
                                                                        background-color: transparent;

                                                                        .card-header {
                                                                            padding: 0;
                                                                        }

                                                                        .card-body {
                                                                            .formio-component-panel {
                                                                                .card-header {
                                                                                    &+.card-body {
                                                                                        .formio-component-panel {
                                                                                            .card {
                                                                                                margin: 0;

                                                                                                .card-body {
                                                                                                    &:first-child {
                                                                                                        padding-top: 0 !important;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &.formio-component-htmlelement {
                                                                    &.alert {
                                                                        font-size: 14px !important;
                                                                        color: #d56d72 !important;

                                                                        p {
                                                                            color: #222222;
                                                                            font-weight: 600;
                                                                        }
                                                                        &+.formio-component-panel {
                                                                            margin-top: 0;

                                                                            .card {
                                                                                background-color: transparent;

                                                                                .card-header {
                                                                                    padding: 0;
                                                                                }

                                                                                .card-body {
                                                                                    padding-top: 2.5rem !important;
                                                                                }
                                                                            }

                                                                            &~.formio-component-panel {
                                                                                .card {
                                                                                    background-color: transparent;

                                                                                    .card-header {
                                                                                        padding: 0;
                                                                                    }

                                                                                    .card-body {
                                                                                        padding-top: 2.5rem !important;
                                                                                    }
                                                                                }

                                                                                &:last-child {
                                                                                    margin-bottom: 0;

                                                                                    .card {
                                                                                        margin-bottom: 0 !important;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        &:first-child {
                                                                            margin-top: 0;
                                                                        }
                                                                        &.formio-hidden {
                                                                            &:first-child {
                                                                                & ~ .formio-component-table {
                                                                                    margin-top: 0;
                                                                                }
                                                                                & ~ .alert:not(.formio-hidden) {
                                                                                    margin-top: 0;

                                                                                    & ~ .formio-component-table,
                                                                                    & ~ .alert:not(.formio-hidden) {
                                                                                        margin-top: 2rem;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                
                                                                &.formio-component-table {
                                                                    &.formio-component-documentsTabTable {
                                                                        margin-top: 0;

                                                                        table {
                                                                            tbody {
                                                                                tr {
                                                                                    &.row {
                                                                                        .formio-component-datagrid {
                                                                                            margin-top: 0px;

                                                                                            table {
                                                                                                &.datagrid-table {
                                                                                                    tbody {
                                                                                                        tr {
                                                                                                            td {
                                                                                                                padding: 0;

                                                                                                                .formio-component-panel {
                                                                                                                    margin-bottom: 0;

                                                                                                                    .card {
                                                                                                                        margin-bottom: 0 !important;
                                                                                                                        border-radius: @roundedCorners;
                                                                                                                        padding: 12px;

                                                                                                                        .card-body {
                                                                                                                            .formio-component-htmlelement {
                                                                                                                                p {
                                                                                                                                    line-height: 1.2;

                                                                                                                                    a {
                                                                                                                                        display: block;
                                                                                                                                        margin-bottom: 2rem;

                                                                                                                                        img {
                                                                                                                                            max-width: 100%;
                                                                                                                                            margin: 12px 0;
                                                                                                                                        }

                                                                                                                                        &:last-child {
                                                                                                                                            margin-bottom: 0;
                                                                                                                                        }
                                                                                                                                    }

                                                                                                                                    span {
                                                                                                                                        &.bullet {
                                                                                                                                            vertical-align: initial;

                                                                                                                                            & ~ a {
                                                                                                                                                margin-top: 10px;
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    &:not(.row) {
                                                                                        >td {
                                                                                            padding-inline-start: 0;

                                                                                            .formio-component-datagrid {
                                                                                                margin-top: 0;
                                                                                            }
                                                                                            >.formio-component-panel {
                                                                                                margin-bottom: 0;
                                                                                                margin-top: 0;

                                                                                                >.card {
                                                                                                    margin-bottom: 0 !important;
                                                                                                    border-radius: @roundedCorners;
                                                                                                    padding: 12px;

                                                                                                    .card-body {
                                                                                                        .formio-component-htmlelement {
                                                                                                            margin-top: 0;

                                                                                                            p {
                                                                                                                line-height: 1.2;

                                                                                                                a {
                                                                                                                    display: block;
                                                                                                                    margin-bottom: 2rem;

                                                                                                                    img, i {
                                                                                                                        max-width: 100%;
                                                                                                                        margin: 12px 0 0;
                                                                                                                    }

                                                                                                                    &:last-child {
                                                                                                                        margin-bottom: 0;
                                                                                                                    }
                                                                                                                }

                                                                                                                span {
                                                                                                                    &.bullet {
                                                                                                                        vertical-align: initial;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                        .alert:not(.formio-hidden) ~ .formio-component-htmlelement:not(.alert) {
                                                                                                            margin-top: 1rem;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            &.dg-action-col {
                                                                                                display: none;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                        @media screen and (max-width: 768px) {
                                                                            table {
                                                                                &.table {
                                                                                    tbody {
                                                                                        tr {
                                                                                            &.row {
                                                                                                flex-direction: column;
                                                                                                margin: 0;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                .formio-component-checkbox {
                                                                    .checkbox {
                                                                        label {
                                                                            input {
                                                                                &[type="checkbox"] {
                                                                                    transform: scale(0);
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            #datarejectionreason {
                                                                textarea {
                                                                    font-size: 14px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .validator-radio {
                                        display: inline-flex;
                                        align-items: center;
                                        flex: 0;
                                        width: fit-content;
                                        cursor: pointer;

                                        input {
                                            display: none;

                                            &.valid {
                                                &:checked {
                                                    &~label {
                                                        &[for*="-yes"] {
                                                            background-color: #80c49b;
                                                            color: white;
                                                            opacity: 1;

                                                            &+label {
                                                                background-color: @accentColor10;
                                                                opacity: 0.6;

                                                                &:hover {
                                                                    background-color: #d56d72;
                                                                    color: white;
                                                                    opacity: 1;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            &.invalid {
                                                &:checked {
                                                    &~label {
                                                        &[for*="-yes"] {
                                                            opacity: 0.6;

                                                            &+label {
                                                                background-color: #d56d72;
                                                                color: white;
                                                                opacity: 1;
                                                            }

                                                            &:hover {
                                                                opacity: 1;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        label {
                                            margin-bottom: 0 !important;
                                            padding: 0.6rem 1rem;
                                            cursor: pointer !important;
                                            transition-duration: 0.3s;
                                            opacity: 0.6;
                                            white-space: nowrap;

                                            &[for*="-yes"] {
                                                background-color: @accentColor10;
                                                border-radius: 6px 0 0 6px;

                                                &:hover {
                                                    background-color: #80c49b;
                                                    color: white;
                                                }
                                            }

                                            &[for*="-no"] {
                                                background-color: @accentColor5;
                                                border-radius: 0 6px 6px 0;

                                                &:hover {
                                                    background-color: #d56d72;
                                                    color: white;
                                                }
                                            }

                                            &:hover {
                                                opacity: 1;
                                            }
                                        }
                                    }
                                }

                                label[for*="-documentsTab"] {
                                    margin-bottom: 0;

                                    h3 {
                                        margin: 0 0 18px;
                                        padding: 0;
                                        font-family: inherit;
                                        font-weight: 600;
                                        font-size: 18px;
                                    }
                                }

                                /* Part B Role form preview new Formio */
                                &.part-b-preview-new-formio {
                                    #tabs-zone {
                                        .panel {
                                            .part-b-forms-tabs {
                                                z-index: 1;
                                                position: relative;

                                                ul {
                                                    &.card-head {
                                                        background-color: transparent;

                                                        >li {
                                                            box-shadow: none;
                                                            height: auto;

                                                            a {
                                                                font-size: 23px;
                                                                color: @accentColor40;
                                                                font-weight: 600;
                                                                padding-inline-start: @panelBodyPadding;
                                                                padding-inline-end: @panelBodyPadding;
                                                                border-top: none;
                                                                border-top-left-radius: @roundedCorners;
                                                                border-top-right-radius: @roundedCorners;
                                                                box-shadow: none;
                                                            }

                                                            &.active {
                                                                a {
                                                                    background-color: @whiteColor;
                                                                    color: @accentColor;
                                                                    box-shadow: 0 11px 20px @black5Color;
                                                                }
                                                            }

                                                            @media screen and (max-width: 680px) {
                                                                a {
                                                                    padding: 2rem;
                                                                    line-height: 1;
                                                                    font-size: 18px;
                                                                }
                                                            }

                                                            @media screen and (max-width: 480px) {
                                                                a {
                                                                    padding: 1rem;
                                                                    font-size: 16px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .panel-body {
                                                &.tab-content {
                                                    position: relative;
                                                    z-index: 2;
                                                    box-shadow: 0 11px 20px @black5Color;
                                                    padding: 23px !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        @buttonAccentColor: @accentColor;

                        [class*="formio-component-panelsendbacktocorrections"] {
                            & + .formio-dialog-buttons button[ref="modalSave"] {
                                display: none;
                            }
                            .form-control.dropdown {
                                padding-inline-end: 34px;
                                padding-inline-start: 14px;
                            }
                            .choices__item {
                                line-height: 2em;
                            }
                            .choices__item--selectable {
                                padding-inline-end: 20px;

                                .choices__button {
                                    margin-inline-end: 0 !important;
                                }
                            }
                            .choices__item [data-tooltip] {
                                &::before,
                                &::after {
                                    display: none;
                                }
                            }
                            .formio-component-select {
                                .choices__button {
                                    margin-right: initial !important;
                                }
                            }
                        }

                        .formio-component-modal-wrapper {
                            .formio-dialog.formio-dialog-theme-default.component-rendering-hidden {
                                z-index: -1;

                                button {
                                    transition-duration: 0s;
                                }
                            }
                        }

                        #refreshJwt {
                            &+.row {
                                &.mt-2 {
                                    @media screen and (max-width: 992px) {
                                        margin-top: 0 !important;
                                        justify-content: flex-end;
                                    }

                                    &+.row {
                                        >div {
                                            &[class^="col-"] {
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        #formio-form-role {
                            > .alert {
                                font-size: 14px !important;

                                &.alert-success {
                                    position: fixed !important;
                                    top: 19px;
                                    inset-inline-end: 0;
                                    z-index: 99999;
                                    width: auto;
                                    max-width: 320px;
                                    background: @successNotification !important;
                                    color: @whiteColor!important;
                                    padding: 1rem 2rem !important;
                                    font-size: 16px !important;
                                    inset-inline-end: 16px;
                                    -webkit-animation-duration: 2s;
                                    animation-duration: 2s;
                                    -webkit-animation-delay: 1s;
                                    animation-delay: 1s;
                                    -webkit-animation-fill-mode: both;
                                    animation-fill-mode: both;
                                    -webkit-animation-name: fadeOut;
                                    animation-name: fadeOut;
                
                                    &::after {
                                        display: none !important;
                                    }
                                }
                                &.alert-danger {
                                    --alert-color: 247, 95, 95;
                                    bottom: auto;
                                    top: 144px;
                                    z-index: 99999;
                                    transition-duration: 0.2s;
                                    padding-inline-start: 1.5rem !important;
                                    border-color: rgb(var(--alert-color)) !important;
                                    box-shadow: 0 5px 20px rgba(var(--alert-color), 0.4);
                                    inset-inline-end: calc(((100vw - 1200px) / 2) - 10px);
                                    width: fit-content;
              
                                    button {
                                        &.close-button {
                                            color: transparent;
                                            position: relative;
                                            top: -10px;
                                            inset-inline-end: -10px;
                                            border-radius: 0 6px 0 0;
                                            height: 37px;
                    
                                            &::before {
                                                font-family: 'Font Awesome 5 Pro';
                                                font-weight: 300;
                                                -moz-osx-font-smoothing: grayscale;
                                                -webkit-font-smoothing: antialiased;
                                                display: inline-block;
                                                font-style: normal;
                                                font-variant: normal;
                                                text-rendering: auto;
                                                line-height: 1;
                                                content: "\F00D";
                                                color: @darkTextColor;
                                                position: absolute;
                                                font-size: 16px;
                                                top: calc(50% - 8px);
                                            }
                                            &:hover {
                                                background: @accentColor10;
                                            }
                                        }
                                    }
                                    p {
                                        font-weight: 600;
                                        color: rgb(var(--alert-color));
                                        line-height: 1.3 !important;
                                    }
                                    ul {
                                        padding-inline-start: 14px;
                                        overflow-y: auto;
                                        width: 100%;
                                        padding-inline-end: 16px;
                                        max-height: ~"min(calc(100vh - 200px), 320px)";
                
                                        li {
                                            margin-bottom: 8px;
                                            font-weight: 600;
                                            transition-duration: 0.15s;
                
                                            &:last-child {
                                                margin-bottom: 0;
                                            }
                                            &:hover {
                                                padding-inline-start: 0;
                                                background: no-repeat;
                    
                                                &::marker {
                                                    content: "\f0a9";
                                                }
                                                span {
                                                    &::after {
                                                        display: none;
                                                    }
                                                }
                                            }
                                            span {
                                                margin-inline-start: 8px;
                                                text-decoration: none;
                                                display: block;
                    
                                                &:hover {
                                                    text-decoration: underline;
                                                }
                                            }
                                            &::marker {
                                                -moz-osx-font-smoothing: grayscale;
                                                -webkit-font-smoothing: antialiased;
                                                display: inline-block;
                                                font-style: normal;
                                                font-variant: normal;
                                                text-rendering: auto;
                                                line-height: 1;
                                                font-family: 'Font Awesome 5 Pro';
                                                font-weight: 400;
                                                content: "\f06a";
                                                color: rgb(var(--alert-color));
                                            }
                                        }
                                        &::-webkit-scrollbar {
                                            width: 6px;
                                            height: 6px;
                                        }
                                        &::-webkit-scrollbar-thumb {
                                            border-radius: 4px;
                                            background-color: rgba(var(--alert-color), 0.8);
                                            transition-duration: .15s;
                                        }
                                        &::-webkit-scrollbar-track {
                                            background-color: rgba(var(--alert-color), 0.3);
                                        }
                                    }
                                    @media screen and (max-width: 1200px) {
                                        inset-inline-end: 2.4rem;
                                    }
                                }
                            }
                            .open-modal-button {
                                --button-bg-color: @buttonAccentColor;
                                border: none !important;
                                background-color: var(--button-bg-color);
                                border-radius: @roundedCorners !important;
                                color: #fff;
                                font-size: 16px;
                                font-weight: 500;
                                gap: 8px;
                                line-height: 1;
                                padding-inline-end: 25px;
                                padding-bottom: 12px;
                                padding-inline-start: 25px;
                                padding-top: 12px;
                                position: relative;
                                text-align: center;
                                text-transform: none;
                                z-index: 1;

                                &.btn-green {
                                    --button-bg-color: @successNotice;
                                    --button-secondary-bg-color: rgba(@successNoticeRGB, 0.35);
                                }
                                &.btn-blue {
                                    --button-bg-color: @infoNotice;
                                    --button-secondary-bg-color: rgba(@infoNoticeRGB, 0.35);
                                }
                                &.btn-orange {
                                    --button-bg-color: @warningNotice;
                                    --button-secondary-bg-color: rgba(@warningNoticeRGB, 0.5);
                                }
                                &.btn-red {
                                    --button-bg-color: @errorNotice;
                                    --button-secondary-bg-color: rgba(@errorNoticeRGB, 0.35);
                                }
                            }
                            [ref="componentModalWrapper"] {
                                margin-bottom: 0px;

                                [ref="modalWrapper"] {
                                    [ref="modalContents"] {
                                        > .formio-component-panel {
                                            --custom-top-margin: 0;

                                            > .card {
                                                padding: 0 !important;

                                                &::after {
                                                    display: none !important;
                                                }
                                            }
                                        }
                                        .formio-component-columns[class*="formio-component-conclusionRow"] {
                                            justify-content: flex-end;
                                            
                                            > [class*="col-"] {
                                                width: auto;
                                            }
                                            .formio-errors {
                                                flex: 0 0 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.sticky-menu {
            #base {
                #content {
                    .section-body {
                        .contain-lg {
                            #formio-form-role {
                                > .alert {
                                    &.alert-danger {
                                        top: 64px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .popup {
            font-size: 14px;
        }

        .ui.popup {
            border-radius: @roundedCorners
        }
        .daterangepicker {
            .ranges li.active,
            .drp-buttons .btn.btn-primary,
            td.active:not(.off),
            td.active:not(.off):hover {
                background-color: @accentColor;
            }
        }

        .formio-dialog.formio-dialog-theme-default .formio-dialog-close {
            inset-inline-end: 10px !important;
            top: 10px;
            right: auto;
            width: 38px;
            height: 38px;
            padding: 0 0.6rem;
        }
        .formio-dialog-content [ref=dialogHeader] {
            padding-right: initial;
            padding-inline-end: 15px;
        }
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes buttonProgress {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

html[dir="rtl"] body.modern-theme #header .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown) i.dropdown {
    left: 0;
    right: auto;
}

.services-select {
    z-index: 1000 !important;
}

.roles-ui-dropdown {
    z-index: 1000 !important;
}