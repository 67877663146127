/******************************************/
/*     Box Section in My Applications     */
/******************************************/
.box {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.16);
}
.box-body {
  padding: 1rem;
}
.box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3.5rem;
  border-bottom: 1px solid #dae3ea;
  font-weight: bold;
}
.box-title {
  color: #404451;
  font-size: 2rem;
}

.box-body {
  display: flex;
  flex-wrap: wrap;
  max-height: 24rem;
  overflow-y: auto;
}

.box-tabbable {
  width: 100%;
  margin: 5px 25px;
  .box-tabs {
    border-bottom: 0;
    .box-tab {
      width: 50%;
      margin-bottom: 20px;
      text-align: center;
      &.active {
        a {
          border: 0;
          border-bottom: 2px solid #186288;
          span {
            color: rgba(0, 0, 0, 0.87);
          }
        }
      }
      a {
        border-bottom: 2px solid rgba(154, 167, 183, 0.3);
        span {
          font-weight: bold;
          color: rgba(154, 167, 183, 1);
        }
      }
    }
  }
}
.box-table {
  width: 100%;
  margin: 5px 25px;
  thead {
    tr {
      border-bottom: 1px solid rgba(154, 167, 183, 0.4);
      th {
        padding-block: 5px;
        padding-inline-start: 0;
        padding-inline-end: 10px;
        text-align: start;
        &:last-of-type {
          padding: 5px 0 5px 10px;
        }
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid rgba(154, 167, 183, 0.15);
      td {
        padding-block: 10px;
        padding-inline-start: 0;
        padding-inline-end: 10px;
        text-align: start;
        font-size: 14px;
        &:last-of-type {
          padding: 10px 0 10px 10px;
        }
      }
    }
  }
}
.box-item {
  flex: 0 0 25%;
  margin: 1.5rem 0;
}
.item {}
.item-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-align: center;
  cursor: pointer;
  opacity: 1;
  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
}
.item-img {
  height: 3rem;
}
.item-name {
  color: #404451;
  font-size: 1.4rem;
}




/***************************/
/*     Main navigation     */
/***************************/
nav.greedy ul.hidden-links.secondary-nav li {
  float: none;
}

.economic-activities {
  display: flex;
  flex-direction: column;
  label {
    display: block;
    margin-bottom: 5px;
    font-size: 12px;
  }
  button {
    align-self: flex-start;
    border: 1px solid #3C8FB9;
    color: #3C8FB9;
    background-color: #ffffff;
    border-radius: 2px !important;
    text-transform: uppercase;
    font-weight: 500;
    order: 2;
    margin-bottom: 1.5rem;
  }
  ul {
    padding: 0;
    margin-bottom: 10px;
    list-style: none;
    &:empty {
      display: none;
    }
    li {
      color: #2267a2;
    }
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
  }
  button.close {
    font-size: 42px;
    opacity: 1;
  }
}

.input-group {
  .input-group-btn {
    button.btn-default {
      padding: 5px 14px;
      background-color: #fff;
      color: #9AA7B7;
      &:hover {
        background-color: #EFEFEF;
      }
    }
  }
}
.datetime-picker-dropdown {
  inset-inline-end: 0;
  inset-inline-start: auto !important;
  li {
    &:focus {
      outline: none;
    }
    &:after,
    &:before {
      display: none !important;
    }
    div {
      &:focus {
        outline: none;
      }
    }
  }
  .uib-timepicker {
    td {
      button.btn-default {
        background-color: #fff;
        border-color: #fff;
        border-radius: 0;
        &:hover {
          background-color: #2267a2;
          color: #FFFFFF;
        }
      }
      &.uib-increment,
      &.uib-decrement {
        .glyphicon {
          color: #313335;
        }
      }
    }
  }
  .uib-datepicker {
    .uib-timepicker,
    .uib-daypicker,
    .uib-monthpicker,
    .uib-yearpicker {
      table {
        th,
        td {
          button.btn-default {
            background-color: #fff;
            border-color: #fff;
          }
        }
        th {
          small {
            color: #000;
          }
        }
        td {
          button.btn-default {
            border-radius: 0;
            &:hover {
              background-color: #EFEFEF;
            }
            span.text-info {
              color: #ccc !important;
            }
            &.active {
              background-color: #EFEFEF;
              color: #313335;
            }
          }
          &.uib-increment {
            .glyphicon {
              color: #313335;
            }
          }
        }
      }
    }
  }
  .btn-group.pull-left,
  .btn-group.pull-right {
    button.btn-default {
      background-color: #fff;
      border-color: #fff;
      border-radius: 0;
      &:hover {
        background-color: #2267a2;
        color: #FFFFFF;
      }
    }
  }
}

@media all and (max-width: 768px) {
    .datetime-picker-dropdown {
        inset-inline-end: auto;
    }
}