body {
    &.modern-theme {
        .secondary-menu {
            &.inspector-menu {
                background-color: @accentColor;

                .contain-lg {
                    display: flex;
                    
                    > nav {
                        min-height: 40px;

                        ul {
                            height: 100%;

                            li {
                                color: @whiteColor;
                                height: 100%;
                                font-weight: 600;
                                font-size: 18px;

                                a {
                                    display: flex;
                                    height: 100%;
                                    padding: 0;
                                    background: transparent;
                                    font-size: 18px;

                                    &[href] {
                                        color: @whiteColor;
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        padding: 0 9px;
                                        margin-block: 0;
                                        margin-inline-start: -9px;
                                        margin-inline-end: 6px;

                                        &:hover,
                                        &:active {
                                            background-color: @black10Color;
                                            text-decoration: none;
                                        }
                                    }
                                    > span {
                                        height: 100%;
                                        align-items: center;
                                        display: flex;
                                    }
                                    &.inspector-text {
                                        .fa-chevron-right {
                                            &[hidden] {
                                                display: flex;
                                                font-size: 10px;
                                                margin-inline-end: 12px;
                                                align-items: center;
                                            }
                                        }
                                    }
                                }
                                .fa-chevron-right {
                                    font-size: 10px;
                                    margin-inline-end: 12px;
                                }
                            }
                        }
                    }
                    .service-bar-user-lang-menu {
                        &[hidden] {
                            display: block;
                            margin-inline-start: auto;
                            margin-inline-end: -1.5rem;

                            .headerbar-right {
                                height: 40px;
                                flex-direction: row-reverse;
                
                                ul {
                                &#language_selector_container {
                                    > li {
                                    &.dropdown-user {
                                        a {
                                        &.dropdown-toggle {
                                            color: @whiteColor;
                                            padding: 0 1.5rem;
                                            height: 40px;
                                            display: flex;
                                            align-items: center;
                                            gap: 6px;
                                            line-height: 1;
                
                                            &:hover {
                                            background: @black10Color;
                                            }
                                            i {
                                            font-size: 16px;
                                            margin-inline-start: 0.8rem;
                                            }
                                        }
                                        }
                                        &.open {
                                        a {
                                            &.dropdown-toggle {
                                            background: @black10Color;
                                            }
                                        }
                                        ul {
                                            &.dropdown-menu {
                                            background-color: @whiteColor;
                                            padding: 0;
                                            inset-inline-end: inherit;
                                            border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;
                                            min-width: 100%;
                
                                            li {
                                                a {
                                                color: @accentColor;
                                                font-weight: 600;
                
                                                &:hover {
                                                    color: @darkTextColor;
                                                    background-color: @pageBgColor;
                                                }
                                                }
                                                &:first-child {
                                                a {
                                                    border-radius: 0 @roundedCorners 0 0;
                                                }
                                                }
                                                &:last-child {
                                                a {
                                                    border-radius: 0 0 @roundedCorners @roundedCorners;
                                                }
                                                }
                                            }
                                            }
                                        }
                                        }
                                    }
                                    }
                                }
                                &#connect {
                                    height: 100%;
                
                                    li {
                                    &.dropdown {
                                        &.user {
                                        height: 100%;
                
                                        a {
                                            text-transform: none;
                                            font-weight: 600;
                
                                            &.dropdown-toggle {
                                            &.left-part {
                                                background: transparent;
                                                margin: 0;
                                                border: none;
                                                padding-inline-start: 0;
                                                padding-inline-end: 0;
                                                border-radius: 0;
                
                                                i {
                                                &.user-icon {
                                                    display: none;
                                                }
                                                }
                                                span {
                                                &.user-name-text {
                                                    padding: 0;
                                                }
                                                }
                                                &:hover {
                                                text-decoration: underline;
                                                }
                                            }
                                            &.user-dropdown-toggle {
                                                background: transparent;
                                                border: none;
                                                padding-inline-end: 1.3rem;
                                                padding-inline-start: 1.3rem;
                                                border-radius: 0;
                                                cursor: pointer;
                
                                                &:hover {
                                                background-color: @black10Color;
                                                }
                                                i {
                                                &.angle-icon {
                                                    margin-inline-end: 0;
                                                    font-weight: 300;
                                                    font-size: 16px;
                                                }
                                                }
                                            }
                                            }
                                        }
                                        &:hover,
                                        &.open {
                                            background-color: @black10Color;

                                            a {
                                                color: @whiteColor;
                                            }
                                        }
                                        &.open {
                                            a {
                                                &.dropdown-toggle {
                                                    &.user-dropdown-toggle {
                                                        background-color: @black10Color;
                        
                                                        i {
                                                            color: @whiteColor;
                                                        }
                                                    }
                                                }
                                            }
                                            ul {
                                            &.dropdown-menu {
                                                width: 100%;
                                                min-width: fit-content;
                                                background-color: @whiteColor;
                                                color: @accentColor;
                                                padding: 0;
                                                border-radius: 0 0 @roundedCorners @roundedCorners;
                
                                                li {
                                                &.divider {
                                                    margin: 0;
                                                    background-color: @accentColor10;
                                                    height: 1px;
                
                                                    &:first-child {
                                                    display: none;
                                                    }
                                                }
                                                a {
                                                    padding: 7px 20px;
                                                    font-size: 13px;
                                                    font-weight: 600;
                                                    color: @darkTextColor;
                                                    
                                                    &:hover {
                                                    background-color: @black5Color;
                                                    }
                                                }
                                                &:last-child {
                                                    a {
                                                    border-radius: 0 0 @roundedCorners @roundedCorners !important;
                                                    }
                                                }
                                                }
                                            }
                                            }
                                        }
                                        }
                                    }
                                    &:not(.dropdown) {
                                        height: 100%;
                
                                        a {
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        border-radius: 0 !important;
                                        background: transparent;
                                        margin: 0 !important;
                                        padding: 0 2rem;
                                        text-transform: none;
                                        font-weight: 600;
                                        box-shadow: none !important;
                
                                        &:hover {
                                            background-color: @black10Color !important;
                                        }
                                        }
                                    }
                                    }
                                }
                                }
                            }
                            @media screen and (max-width: 1200px) {
                                margin-inline-end: 0;
                            }
                        }
                    }
                }
            }
        }
        #base {
            background-color: @pageBgColor;
      
            #content {
                .section-body {
                    .contain-lg {
                        .row {
                            .panel {
                                .panel-body {
                                    #entreprise-grid {
                                        &.inspector-board {
                                            table {
                                                thead {
                                                    tr {
                                                        &:first-child {
                                                            th {
                                                                padding-inline-start: 20px;
                                                                padding-inline-end: 20px;

                                                                &.service-column {
                                                                    width: 20%;
                                                                }
                                                                &:first-child {
                                                                    width: 12%;
                                                                }
                                                            }
                                                        }
                                                        &.filters {
                                                            td {
                                                                padding-inline-start: 0;
                                                                padding-inline-end: 0;
                                                                border: none;

                                                                input,
                                                                select {
                                                                    border: solid 1px @accentColor10;
                                                                    background-color: transparent;
                                                                    border-radius: @roundedCorners;
                                                                    margin-top: 18px;
                                                                    height: 34px;
                                                                    box-shadow: none;
                                                                    margin-inline-end: 20px;
                                                                    width: calc(100% - 20px);
                                                                    margin-bottom: 12px;
                                                                    outline: none;
                                                                    padding: 4px 20px;
                                                                    font-weight: 600;
                                                                    font-size: 13px;
                                                                    color: @darkTextColor;
                                        
                                                                    &::placeholder {
                                                                      color: @accentColor50;
                                                                      font-weight: 600;
                                                                    }
                                                                }
                                                                select {
                                                                    padding-block: 4px;
                                                                    padding-inline-start: 15px;
                                                                    padding-inline-end: 32px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                tbody {
                                                    tr {
                                                        background-color: transparent;
                                  
                                                        td {
                                                            background-color: transparent;
                                                            border: none;
                                                            border-bottom: solid 1px @accentColor10;
                                                            padding-inline-start: 20px;
                                                            padding-inline-end: 20px;
                                                            font-size: 15px;
                                                            font-weight: 500;
                                                            color: @darkTextColor;
                                                            
                                                            &.regist-status {
                                                                file-status {
                                                                    padding-inline-end: 15px;
                                        
                                                                    span {
                                                                        &.label {
                                                                            background-color: transparent;
                                                                            padding: 0;
                                                
                                                                            &.label-info {
                                                                                color: @appStatusPending;
                                                                            }
                                                                            &.label-default {
                                                                                color: @appStatusDraft;
                                                                            }
                                                                            &.label-warning {
                                                                                color: @appStatusAction;
                                                                            }
                                                                            &.label-success {
                                                                                color: @appStatusProcessed;
                                                                            }
                                                                            &.label-danger {
                                                                                color: @appStatusRejected;
                                                                            }
                                                                        }
                                                                    }
                                                                    &:last-child {
                                                                        padding-inline-end: 0;
                                                                    }
                                                                    &:empty {
                                                                        display: none;
                                                                    }
                                                                }
                                                            }
                                                            &.date-col {
                                                                font-size: 12px;
                                                                font-weight: 600;
                                                                text-align: start;
                                                            }
                                                            &.text-center {
                                                                text-align: start;
                                                            }
                                                            span {
                                                                &.badge {
                                                                    background: transparent;
                                                                    color: @darkTextColor;
                                                                    padding: 0;
                                                                    display: flex;
                                                                    font-weight: 500;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .table-paging-container {
                                                text-align: start;
                                                background-color: transparent;
                                    
                                                dir-pagination-controls {
                                                    display: flex;

                                                    ul {
                                                        &.pagination {
                                                            background-color: @accentColor5;
                                                            border-radius: @roundedCorners;
                                                            overflow: hidden;
                                                            margin-top: 32px;
                                                            margin-bottom: 0;

                                                            li {
                                                                display: inline-flex;
                                                                margin-inline-end: 1px;

                                                                a {
                                                                    background-color: transparent;
                                                                    height: 46px;
                                                                    display: flex;
                                                                    align-items: center;
                                                                    border-radius: @roundedCorners;
                                                                    color: @darkTextColor;
                                                                    font-weight: 600;
                                                                    font-size: 12px;
                                                                }
                                                                &.active,
                                                                &:hover {
                                                                    a {
                                                                        background-color: @accentColor10;
                                                                    }
                                                                }
                                                                &:first-child,
                                                                &:last-child {
                                                                    a {
                                                                        color: transparent;
                                                                        position: relative;
                                                                        width: 38px;

                                                                        &::before {
                                                                            font-family: 'Font Awesome 5 Pro';
                                                                            font-weight: 300;
                                                                            -moz-osx-font-smoothing: grayscale;
                                                                            -webkit-font-smoothing: antialiased;
                                                                            display: inline-block;
                                                                            font-style: normal;
                                                                            font-variant: normal;
                                                                            text-rendering: auto;
                                                                            line-height: 1;
                                                                            font-size: 22px;
                                                                            color: @darkTextColor;
                                                                            position: absolute;
                                                                        }
                                                                    }
                                                                }
                                                                &:first-child {
                                                                    a {
                                                                        &::before {
                                                                            content: "\f104";
                                                                        }
                                                                    }
                                                                }
                                                                &:last-child {
                                                                    a {
                                                                        &::before {
                                                                            content: "\f105";
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}