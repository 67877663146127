table {
    &.table {
        width: 100%;
        margin: 0;
        >thead {
            >tr {
                >th {
                    color: @blue-dark;
                    font-weight: 600;
                    font-size: 11px;
                    text-transform: uppercase;
                    padding: 1rem 2rem;
                    line-height: 1;
                    text-align: start;
                    letter-spacing: 0.125rem;
                    position: relative;

                    .sortorder {
                        position: absolute;
                        top: .775rem;
                        inset-inline-end: 2rem;
        
                        &:after {
                            position: absolute;
                            top: 0;
                            content: "\f0d8";
                            font-family: 'Font Awesome 5 Pro';
                            font-weight: 700;
                            -moz-osx-font-smoothing: grayscale;
                            -webkit-font-smoothing: antialiased;
                            display: inline-block;
                            font-style: normal;
                            font-variant: normal;
                            text-rendering: auto;
                            line-height: 1;
                            color: lighten(@grey, 15%);
                            font-size: 1.5rem;
                            border: none;
                        }
        
                        &.reverse:after {
                            content: "\f0d7";
                        }
                    }

                    &:hover {
                        background-color: @main-bg;
                    }
                }

                &.filters {
                    >td {
                        vertical-align: middle;
                        padding: 1rem .5rem 1rem;
            
                        &:first-child {
                            padding-inline-start: 1.5rem;
                        }
                    }
                }
            }
        }
        >tbody {
            >tr {
                >td {
                    padding: 1.5rem 2rem;
                    line-height: 1.5;
                    text-align: start;
                    font-size: 1.5rem;
                    &.small {
                        font-size: 1.2rem;
                    }

                    &.cell-description {
                        font-size: 1.4rem;
                        line-height: 1.5;
                    }

                    .ink-reaction {
                        opacity: 0;
                        transition: all .3s;
                    }

                    &.cell-v-middle {
                        vertical-align: middle;
                    }

                    &.cell-no-border {
                        border: none !important;
                    }

                    &.cell-link {
                        font-weight: 500;
                        line-height: 1.5rem;
                        font-size: 1.6rem;
                        color: @blue;
                    }

                    &.cell-img {
                        padding-top: 0;
                        padding-bottom: 0;
                    }

                    .icon-small {
                        width: auto;
                        height: 2rem;
                    }
                }

                &.row-deleting {
                    background: @main-bg;           
                    * {
                        color: @grey;
                        cursor: wait;
                    }
                }

                &:hover {
                    .ink-reaction {
                        opacity: 1;
                        transition: all .3s;
                    }
                    &.cell-link {
                        color: @blue-dark;
                    }
                }
            }
            >tr:first-child {
                >td {
                    border-top: none;
                }
            }
        }

        &.table-panel {
            >tbody {
                >tr {
                    >td {
                        padding-block: 1.5rem;
                        padding-inline-start: 0;
                        padding-inline-end: 0.5rem;

                        span.label {
                            display: inline-block;
                            padding: 4px 5px;
                        }
                    }
                }
            }
        }

        th,
        td {
            &.text-center {
                text-align: center;
            }
        }

        &.table-condenced {
            >tbody {
                >tr {
                    >td {
                        padding-top: 0.5rem !important;
                        padding-bottom: 0.5rem !important;
                    }
                }
            }
        }

        &.business-list-table {
            > thead {
                > tr {
                    > th {
                        color: #444444;
                        text-transform: none;
                        padding: 1rem;
                        letter-spacing: 0;
                        vertical-align: middle;
                    }
                }
            }
            > tbody {
                > tr {
                    &.active {
                        > td {
                            background: #F7F7F7;
                            &:nth-child(2) {
                                color: @primary;
                            }
                        }
                    }
                    > td {
                        padding: 1.5rem 1rem;

                        .form-control {
                            padding-inline-start: 10px;
                            height: auto;
                            background-color: white;
                            margin-top: -2px;
                        }

                        &.row-toggler {
                            padding: 1.5rem 0 1.5rem 1rem;
                            .fa {
                                margin-top: .25rem;
                            }
                        }

                        &.business-files {
                            background: #F7F7F7 !important;
                            border-top-color: fade(@primary,25%);

                            thead tr th {
                                background: #F7F7F7;

                                &:hover {
                                    background: #F7F7F7;
                                }
                            }
                            tbody tr {
                                td {
                                    background: #F7F7F7;
                                }
                                &:hover {
                                    td {
                                        background: #f2f2f2;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.financial-table {
            thead {
                tr {
                    th {
                        color: black;
                        padding: 2rem 1.5rem;
                        border: none;

                        &:last-child {
                            text-align: end;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        font-size: 14px;
                        font-weight: 400;
                        border: solid 2px white;
                        padding: 1rem 1.5rem;

                        &:last-child {
                            font-weight: 500;
                            text-align: end;
                        }
                    }
                }
            }
        }
    }
}

.table-paging-container {
    text-align: center;
    background-color: #F7F7F8;

    ul.pagination {
        margin: 16px 0 8px;
        border-radius: 0;

        li a {
            border: none;
        }

        li.disabled a {
            background-color: #eeeeee;
        }

        li:first-child > a,
        li:last-child > a {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
    }
}