body {
  &.modern-theme {
    #base {
      #content {
        .affix-menu-size-container {
          height: 124px;

          .user-steps-menu {
            background-color: transparent;

            .user-steps-menu-wrapper {
              position: relative;

              .current-service-container {
                background: @accentColor;
                box-shadow: -320px 0 0 @accentColor, -400px 0 0 @accentColor, -800px 0 0 @accentColor, -1200px 0 0 @accentColor, -2400px 0 0 @accentColor, 320px 0 0 @accentColor, 400px 0 0 @accentColor, 800px 0 0 @accentColor, 1200px 0 0 @accentColor, 2400px 0 0 @accentColor;
                height: 40px;

                .current-service-text {
                  color: @whiteColor;
                  height: 100%;
                  font-weight: 400;

                  a {
                    &.home-button {
                      color: @whiteColor;
                      height: 100%;
                      display: flex;
                      align-items: center;

                      &:hover,
                      &:active {
                        background-color: @black10Color;
                        text-decoration: none;
                      }
                    }
                  }
                  > span {
                    &.be-title {
                      background-color: @black10Color !important;
                      font-size: 70%;
                      border-radius: @roundedCorners;
                      color: @whiteColor;
                      padding: 0 10px;
                      margin-inline-start: 6px;
                    }
                  }
                  @media screen and (max-width: 480px) {
                    line-height: 1;
                    font-size: 12px;
                  }
                }
                .service-bar-user-lang-menu {
                  margin-inline-start: auto;
                  margin-inline-end: -1.5rem;

                  .headerbar-right {
                    height: 40px;
                    flex-direction: row-reverse;
      
                    ul {
                      padding-inline-start: 0;

                      &#language_selector_container {
                        > li {
                          &.dropdown-user {
                            a {
                              &.dropdown-toggle {
                                color: @whiteColor;
                                padding: 0 1.5rem;
                                height: 40px;
                                display: flex;
                                align-items: center;
                                gap: 6px;
                                line-height: 1;
      
                                &:hover {
                                  background: @black10Color;
                                }
                                i {
                                  font-size: 16px;
                                  margin-inline-start: 0.8rem;
                                }
                              }
                            }
                            &.open {
                              a {
                                &.dropdown-toggle {
                                  background: @black10Color;
                                }
                              }
                              ul {
                                &.dropdown-menu {
                                  background-color: @whiteColor;
                                  padding: 0;
                                  inset-inline-end: 0;
                                  inset-inline-start: inherit;
                                  border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;
                                  min-width: 100%;
      
                                  li {
                                    a {
                                      color: @accentColor;
                                      font-weight: 600;
      
                                      &:hover {
                                        color: @darkTextColor;
                                        background-color: @pageBgColor;
                                      }
                                    }
                                    &:first-child {
                                      a {
                                        border-radius: 0 @roundedCorners 0 0;
                                      }
                                    }
                                    &:last-child {
                                      a {
                                        border-radius: 0 0 @roundedCorners @roundedCorners;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            @media screen and (max-width: 768px) {
                              padding: 0;
                            }
                          }
                        }
                      }
                      &#connect {
                        height: 100%;
      
                        li {
                          &.dropdown {
                            &.user {
                              height: 100%;
      
                              a {
                                text-transform: none;
                                font-weight: 600;

                                &.dropdown-toggle {
                                  &.left-part {
                                    background: transparent;
                                    margin: 0;
                                    border: none;
                                    padding-inline-start: 1.5rem;
                                    padding-inline-end: 0;
                                    border-radius: 0;
                                    cursor: pointer;
      
                                    i {
                                      &.user-icon {
                                        display: none;
                                      }
                                    }
                                    span {
                                      &.user-name-text {
                                        padding: 0;

                                        @media screen and (max-width: 768px) {
                                          display: block;
                                        }
                                      }
                                    }
                                    &:nth-last-child(2) {
                                      padding-inline-end: 1.5rem;
                                      cursor: default;
                                    }
                                  }
                                  &.user-dropdown-toggle {
                                    background: transparent;
                                    border: none;
                                    padding-inline-end: 1.3rem;
                                    padding-inline-start: 1.3rem;
                                    border-radius: 0;
                                    cursor: pointer;

                                    i {
                                      &.angle-icon {
                                        margin-inline-end: 0;
                                        font-weight: 300;
                                        font-size: 16px;
                                      }
                                    }
                                    &:last-child {
                                      display: none;
                                    }
                                  }
                                }
                              }
                              &:where(:hover, .open) {
                                &:not(.no-permissions) {
                                  background-color: @black10Color;
                                }                                
                                a {
                                  color: @whiteColor;
                                }
                              }
                              &.open {
                                a {
                                  &.dropdown-toggle {
                                    &.user-dropdown-toggle {
                                      i {
                                        color: @whiteColor;
                                      }
                                    }
                                  }
                                }
                                ul {
                                  &.dropdown-menu {
                                    width: 100%;
                                    min-width: fit-content;
                                    background-color: @whiteColor;
                                    color: @accentColor;
                                    padding: 0;
                                    border-radius: 0 0 @roundedCorners @roundedCorners;
      
                                    li {
                                      &.divider {
                                        margin: 0;
                                        background-color: @accentColor10;
                                        height: 1px;

                                        &:first-child {
                                          display: none;
                                        }
                                      }
                                      a {
                                        padding: 7px 20px;
                                        font-size: 13px;
                                        font-weight: 600;
                                        color: @darkTextColor;
                                        
                                        &:hover {
                                          background-color: @black5Color;
                                        }
                                      }
                                      &:last-child {
                                        a {
                                          border-radius: 0 0 @roundedCorners @roundedCorners !important;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          &:not(.dropdown) {
                            height: 100%;

                            a {
                              height: 100%;
                              display: flex;
                              align-items: center;
                              border-radius: 0 !important;
                              background: transparent;
                              margin: 0 !important;
                              padding: 0 2rem;
                              text-transform: none;
                              font-weight: 600;
                              box-shadow: none !important;

                              &:hover {
                                background-color: @black10Color !important;
                              }
                            }
                          }
                        }
                      }
                    }
                    @media screen and (max-width: 768px) {
                      background-color: transparent;
                      top: initial;
                      inset-inline-start: initial;
                      inset-inline-end: initial;
                      min-height: 40px;
                    }
                  }
                  @media screen and (max-width: 1200px) {
                    margin-inline-end: 0;
                  }
                  @media screen and (max-width: 680px) {
                    .headerbar-right {
                      display: none;
                      position: absolute;
                      z-index: 9999;
                      inset-inline-end: 0;
                      background: #4a6288;
                      top: 40px;
                    }
                    &::after {
                      content: "☰";
                      display: block;
                    }
                    &:hover,
                    &:focus-within {
                      .headerbar-right {
                        display: block;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    /* New Formio */
    &.formio-new {
      #base {
        #content {
          .demo-page-menu {
            .contain-lg {
              .current-service-container {
                .current-service-text {
                  .demo-heading-title {
                    display: none;
                  }
                }
              }
            }
          }
          .affix-menu-size-container {
            &.demo-modern {
              display: none;
            }
            &.affix {
              & ~ .section-body {
                .contain-lg {
                  #formio-form-wizard {
                    > .alert {
                      &.alert-danger {
                        top: 46px;

                        ul {
                          max-height: ~"min(calc(100vh - 120px), 320px)";
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ul {
      padding-inline-start: 0;
    }
  }
}